import React, { useState, useRef, useEffect } from "react";
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { Typography, Slider as MuiSlider, Modal, Select, MenuItem, InputLabel, FormControl, Checkbox, FormGroup } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import HowItWorks from "../components/HowItWorks";
const API_BASE_URL = process.env.REACT_APP_BASE_URL;



// import homeHeroImage from '../images/home_hero.jpg';
const activities = [
  {
    title: "Outdoors",
    description: "Hike, bike, and explore peaceful nature.",
    emoji: ["🥾", "🌅"],
    filterKey: "outdoors",
  },
  {
    title: "Food & Drink",
    description: "Discover new restaurants and bars.",
    emoji: ["🥘", "🍹"],
    filterKey: "food",
  },
  {
    title: "Fitness & Sports",
    description: "Work out, do yoga, and play sports.",
    emoji: ["💪", "🧘"],
    filterKey: "fitness"
  },
  {
    title: "Arts & Culture",
    description: "Visit museums, theaters, and art events.",
    emoji: ["🎭", "🖼️"],
    filterKey: "arts",
  },
  {
    title: "Events",
    description: "Attend local festivals and gatherings.",
    emoji: ["🎉", "🎤"],
    filterKey: "events",
  },
  {
    title: "Hobbies",
    description: "Share interests like gardening or photography.",
    emoji: ["📸", "🌿"],
    filterKey: "hobbies",
  },
  {
    title: "Nightlife",
    description: "Enjoy clubs, bars, and late-night events.",
    emoji: ["🪩", "🍻"],
    filterKey: "night",
  },
  {
    title: "Online",
    description: "Connect through gaming and video chats.",
    emoji: ["🎮", "💻"],
    filterKey: "online",
  },
  {
    title: "Safety",
    description: "Provide company for safe walks and outings.",
    emoji: ["🚶‍♀️", "🦺"],
    filterKey: "night",
  }
];



const Home = () => {
  const sliderRef = useRef(null);
  const resultsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [listings, setListings] = useState([]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [isHowItWorksOpen, setHowItWorksOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const [filterCount, setFilterCount] = useState(0);



  const [filters, setFilters] = useState(() => {
    // Check if filters are in localStorage
    const savedFilters = localStorage.getItem("filters");
    return savedFilters ? JSON.parse(savedFilters) : {
      location: "Miami, FL",
      priceRange: [15, 500],
      gender: "Any",
      ageRange: [18, 50],
      categories: {
        outdoors: true,
        food: true,
        fitness: true,
        arts: true,
        events: true,
        hobbies: true,
        night: true,
        online: true,
        safety: true,
      },
    };
  });

  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  const categoryLabels = {
    outdoors: 'Outdoors',
    food: 'Food & Drink',
    fitness: 'Fitness & Sports',
    arts: 'Arts & Culture',
    events: 'Events',
    hobbies: 'Hobbies',
    night: 'Nightlife',
    online: 'Online',
    safety: 'Safety'
  }

  const fetchListings = async (city) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/listings?city=${encodeURIComponent(city)}`);
      if (!response.ok) {
        throw new Error("Failed to fetch listings");
      }
      const data = await response.json();
      setListings(data); // Update the state with fetched listings
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  };



  const carouselSettings = {
    infinite: true,   // Enable looping
    slidesToScroll: 1, // Scroll one card at a time
    slidesToShow: 3,
    arrows: false,     // We'll use custom arrows
    speed: 1500,        // Animation speed
    focusOnSelect: true,
    centerMode: true,  // Center the cards for a more balanced look
    centerPadding: '40px',
    initialSlide: 1,
    responsive: [
      {
        // Mobile view (max-width: 768px)
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Show 2 cards at a time on mobile
          centerPadding: '1px', // Adjust padding for mobile
        },
      },
      {
        // Desktop view (default, greater than 768px)
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 cards at a time on desktop
          centerPadding: '20px', // Adjust padding for desktop
        },
      },
    ]
  };

  const openHowItWorks = () => {
    setHowItWorksOpen(true);
  };

  const closeHowItWorks = () => {
    setHowItWorksOpen(false);
  }; 

  const toggleFilterModal = () => setFilterModalOpen(!filterModalOpen);

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  }

  const areAllSelected = Object.values(filters.categories).every(Boolean);
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const updatedCategories = Object.fromEntries(
      Object.keys(filters.categories).map((key) => [key, checked])
    );
    handleFilterChange("categories", updatedCategories);
  };

  const handleLocationChange = (event) => {
    const selectedCity = event.target.value;
    handleFilterChange('location', selectedCity);
    fetchListings(selectedCity);
  };

  useEffect(() => {
    fetchListings(filters.location);
  }, [filters.location]);

  const handleMouseDown = () => {
    setIsDragging(false);
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  const handleCardClick = (filterKey) => {
    // Update the filters to only select the clicked category
    if (!isDragging) {
      setFilters((prev) => ({
        ...prev,
        categories: {
          ...Object.keys(prev.categories).reduce(
            (acc, key) => ({ ...acc, [key]: key === filterKey }),
            {}
          ),
        },
      }));
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };


  const valueLabelFormat = (value) => `$${value}`;

  const filteredListings = listings.filter((listing) => {
    const age = currentYear - new Date(listing.activitybuddy_info.date_of_birth).getFullYear();
    const price = listing.activitybuddy_info.hourly_price;
    const matchesCategory = Object.entries(filters.categories).some(
      ([key, isSelected]) => isSelected && listing.activitybuddy_info.categories[key]
    );
    const matchesGender =
      filters.gender === "Any" || listing.gender === filters.gender;

    // Apply both age and price filters
    return (
      age >= filters.ageRange[0] &&
      age <= filters.ageRange[1] &&
      price >= filters.priceRange[0] &&
      price <= filters.priceRange[1] &&
      matchesCategory &&
      matchesGender
    );
  });
  useEffect(() => {
    let count = 0;

    // Check if price range has been modified
    if (filters.priceRange[0] !== 15 || filters.priceRange[1] !== 500) count++;

    // Check if gender is selected (not "Any")
    if (filters.gender !== "Any") count++;

    // Check if any category is selected
    const selectedCategories = Object.values(filters.categories).filter(Boolean).length;
    if (selectedCategories < Object.keys(filters.categories).length) count++;

    // Update the filter count state
    setFilterCount(count);
  }, [filters]);

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Find your perfect companion for any activity</h1>
          <h3 onClick={openHowItWorks} className="how-it-works-link">How does it work?</h3>
        </div>
      </section>



      {/* Activity Section */}
      <section className="activity-section">

        <h3 className="activity-categories-header">Activity Categories</h3>
        <div className="carousel-container">
          {/* Left Arrow */}
          <div className="carousel-arrow left" onClick={() => sliderRef.current.slickPrev()} >
            <ArrowBackIos />
          </div>

          {/* Swipeable View */}
          <Slider {...carouselSettings} ref={sliderRef}>
            {activities.map((activity, idx) => (
              <div key={idx}
                className="activity-card"
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={() => handleCardClick(activity.filterKey)}>
                <h3>{activity.title}</h3>
                <p>{activity.description}</p>
                <div className="emoji-row">
                  {activity.emoji.map((icon, i) => (
                    <span key={i}>{icon}</span>
                  ))}
                </div>
              </div>
            ))}
          </Slider>

          {/* Right Arrow */}
          <div className="carousel-arrow right" onClick={() => sliderRef.current.slickNext()}>
            <ArrowForwardIos />
          </div>
        </div>
      </section>
      <p className="refund-notice">100% refund if you cancel at least 24 hours before your activity starts!</p>
      <div className="coming-soon-parent">
        <section ref={resultsRef} className="filters">
          <div className="filter-container">
            {/* Location Dropdown */}
            <div className="filter-item">
              <FormControl
                variant="outlined"
                fullWidth
                className="location-select"
              >
                <InputLabel className="location-label">Location</InputLabel>
                <Select
                  label="Location"
                  value={filters.location}
                  onChange={handleLocationChange}
                  defaultValue="Miami, FL" // Preselected value
                  classes={{
                    icon: 'location-dropdown-icon', // Add class to the dropdown arrow
                  }}
                  MenuProps={{
                    classes: {
                      paper: 'location-menu', // Add class to the popup menu
                    },
                  }}
                >
                  <MenuItem value="Miami, FL" className="location-menu-item">Miami, FL</MenuItem>
                  <MenuItem value="Tampa, FL" className="location-menu-item">Tampa, FL</MenuItem>
                </Select>
                <p className="home-more-locations-text">Now Hiring in Seattle and San Francisco!</p>
              </FormControl>
            </div>

            <div className="filter-item desktop-only">
              <Typography gutterBottom className="age-label">Age</Typography>
              <MuiSlider
                value={filters.ageRange}
                onChange={(_, newValue) => handleFilterChange('ageRange', newValue)} // handle slider value change
                valueLabelDisplay="auto"
                min={18}
                max={100}
                marks={[
                  { value: filters.ageRange[0], label: filters.ageRange[0] }, // Show label for min
                  { value: filters.ageRange[1], label: filters.ageRange[1] }, // Show label for max
                ]}
                step={1}
                className="age-slider"
              />
            </div>
            <button className="open-filters-button" onClick={toggleFilterModal}>Filters {filterCount > 0 && `(${filterCount})`}</button>
          </div>
        </section>

        {/* User Cards */}
        <section className="user-cards-section">

          <div className="user-cards-container">
            {filteredListings.map((listing) => (
              <Link className="user-card" to={`/listing/${listing.activitybuddy_info.listing_id}`} key={listing.activitybuddy_info.listing_id}>
                <img className="user-image" src={listing.activitybuddy_info.profile_photo} alt={listing.first_name} />
                <div className="user-info">
                  <span className="user-name">
                    {listing.first_name.charAt(0).toUpperCase() + listing.first_name.slice(1).toLowerCase()}
                  </span>
                  <span className="user-age">
                    {(() => {
                      const currentDate = new Date();
                      const birthDate = new Date(listing.activitybuddy_info.date_of_birth);
                      let age = currentDate.getFullYear() - birthDate.getFullYear();

                      // Check if the birthday has passed this year
                      const currentMonth = currentDate.getMonth();
                      const currentDay = currentDate.getDate();
                      const birthMonth = birthDate.getMonth();
                      const birthDay = birthDate.getDate();

                      if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
                        age--;  // Subtract 1 if birthday hasn't passed yet
                      }

                      return `, ${age}`;
                    })()}
                  </span>
                  <span className="user-price">${listing.activitybuddy_info.hourly_price}/hr</span>
                </div>
              </Link>
            ))}
          </div>
        </section>
        {/* Filter Modal */}
        <Modal open={filterModalOpen}
          onClose={toggleFilterModal}
          BackdropProps={{
            timeout: 500,
            style: { backgroundColor: 'rgba(163, 163, 163, 0.7)' } // Custom backdrop color
          }}
        >
          <div className="filter-modal">
            {/* Gender Filter */}
            <Typography className="filters-modal-header">Filters</Typography>
            <FormControl className="gender-filter" variant="outlined">
              <InputLabel className="gender-label">Gender</InputLabel>
              <Select
                label="Gender"
                value={filters.gender}
                onChange={(e) => handleFilterChange("gender", e.target.value)}
                classes={{
                  icon: 'location-dropdown-icon', // Add class to the dropdown arrow
                }}
                MenuProps={{
                  classes: {
                    paper: 'location-menu', // Add class to the popup menu
                  },
                }}
              >
                <MenuItem value="Any">Any</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <div className="mobile-only">
              <Typography gutterBottom className="age-label">Age</Typography>
              <MuiSlider
                value={filters.ageRange}
                onChange={(_, newValue) => handleFilterChange('ageRange', newValue)} // handle slider value change
                valueLabelDisplay="auto"
                min={18}
                max={100}
                marks={[
                  { value: filters.ageRange[0], label: filters.ageRange[0] }, // Show label for min
                  { value: filters.ageRange[1], label: filters.ageRange[1] }, // Show label for max
                ]}
                step={1}
                className="age-slider"
              />
            </div>
            <FormGroup>
              <Typography className="price-label">Price per hour</Typography>
              <MuiSlider
                value={filters.priceRange}
                defaultValue={filters.priceRange}
                onChange={(_, newValue) => handleFilterChange('priceRange', newValue)} // handle slider value change
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
                min={15}
                max={500}
                marks={[
                  { value: filters.priceRange[0], label: valueLabelFormat(filters.priceRange[0]) }, // Show label for min
                  { value: filters.priceRange[1], label: valueLabelFormat(filters.priceRange[1]) }, // Show label for max
                ]}
                step={5}
                className="price-slider"
              />
            </FormGroup>
            {/* Categories */}
            <FormGroup>
              <Typography className="category-label">Categories</Typography>
              <div className="category-checkbox-container">
                <div className="checkbox-row">
                  <Checkbox checked={areAllSelected} onChange={handleSelectAll} />
                  <span className="checkbox-label">Select All</span>
                </div>
                {Object.entries(filters.categories).map(([key, isSelected]) => (
                  <div className="checkbox-row" key={key}>
                    <Checkbox
                      checked={isSelected}
                      onChange={(e) => {
                        const updatedCategories = {
                          ...filters.categories,
                          [key]: e.target.checked,
                        };
                        handleFilterChange("categories", updatedCategories);
                      }}
                    />
                    <span className="checkbox-label">{categoryLabels[key]}</span>
                  </div>
                ))}
              </div>
            </FormGroup>


            <button className="apply-filters-button" onClick={toggleFilterModal}>Apply Filters</button>
          </div>
        </Modal>
        <HowItWorks isOpen={isHowItWorksOpen} onClose={closeHowItWorks} />
      </div >
      < Footer />
    </>
  );
};

export default Home;
