import React from 'react';
import './About.css';
import Navbar from '../components/NavBar';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import { useAuth } from "../components/authContext";


const About = () => {

  const { user } = useAuth();


  return (
    <>
      <Navbar />
      <div className="about-container">
        {/* Header Section */}
        <header className="about-header">
          <h1>Get To Know Us</h1>
          <p>Life's Better When You Live It With Others</p>
        </header>

        <section className="about-section">
          <h2>Turning Moments Into Memories</h2>
          <p>
          In a world where digital likes can’t replace real-life moments, ActivityBuddy reimagines how people connect. We’re not here to just fill your calendar — we’re here to fuel your life with meaningful experiences. From spontaneous day trips to discovering hidden gems in your city or simply sharing a meal with someone new, ActivityBuddy helps you make the most of everyday moments.</p>
          <p>
          Feeling isolated is more common than ever, and building relationships can be tough. That’s why ActivityBuddy makes it easy to meet like-minded people through shared experiences. Whether you’re new in town or looking to break out of your routine, we create opportunities for real connections that turn ordinary activities into unforgettable memories.</p>
        </section>

 

      {/* What We Offer Section */}
      <section className="about-section">
        <h2>Why ActivityBuddy?</h2>
        <ul>
          <li><strong>Unforgettable Moments:</strong> Whether you’re exploring new hobbies, tackling a fitness goal, or simply need someone to chill with, ActivityBuddy gives you the tools to make meaningful connections on your terms.</li>
          <li><strong>Mental Health Focused:</strong> We get it, life can feel lonely. That’s why we’re passionate about building a community that supports your mental well-being. Shared experiences aren’t just fun—they’re proven to boost happiness and reduce stress.</li>
          <li><strong>Safety First:</strong> We prioritize your safety with Stripe verification for every ActivityBuddy, ensuring real, trustworthy connections. All payments are securely processed through Stripe, and our reporting system gives you a way to flag issues, adding extra transparency and peace of mind.</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>How It Works</h2>
        <ul>
          <li><strong>Step 1: Choose an Activity</strong> Decide what you want to do — whether it’s hiking, dining, workouts, or just hanging out. Any legal, safe, and platonic activity works. For safety, we recommend public places.</li>
          <li><strong>Step 2: Find Your ActivityBuddy</strong> Browse listings of available ActivityBuddies based on your preferences. Use filters like location, activity categories, and age to find the perfect match. For safety, every ActivityBuddy is verified through Stripe, so no fake names or shady stuff.</li>
          <li><strong>Step 3: Send a Booking Request</strong> Once you’ve found the right ActivityBuddy, fill out the booking form with activity details — including what, where, when, and how long. A payment hold will be placed upon submission.</li>
          <li><strong>Step 4: Discuss Details Through Messages</strong> After submitting a booking request, you'll be connected with your ActivityBuddy through our secure in-app messaging. Use this to finalize activity details and expectations. For safety and support, all communication should stay within the platform.</li>
          <li><strong>Step 5: Wait For Confirmation</strong> Leading up to your activity, your ActivityBuddy will either accept or decline the request. Both you and the ActivityBuddy can cancel up to 24 hours before the activity starts. If an activity is declined, canceled, or expires, you’ll receive a full refund.</li>
          <li><strong>Step 6: Enjoy and Leave a Review</strong> Meet up with your ActivityBuddy and have fun! Remember, any costs related to the activity (like tickets, meals, or rentals) are your responsibility. Afterwards, leave a review to share your experience and help others in the community.</li>

        </ul>
      </section>


      {/* Our Vision Section */}
      <section className="about-section">
        <h2>Looking Ahead</h2>
        <p>
          At ActivityBuddy, we’re committed to building a community where meaningful connections thrive. As we grow across the U.S. and continue to enhance the platform, our focus will always be on delivering an effortless and enriching experience for everyone. We’re dedicated to constantly improving and evolving to better serve you, with a focus on creating more opportunities for shared experiences and lasting memories. Join us on this exciting journey — together, we’ll build a world where connections become the foundation for lifelong friendships.
        </p>
      </section>
      {!user ? ( // Correct conditional rendering
        <section className="join-movement-section">
          <div className="join-movement-container">
            <h2 className="join-movement-header">Join The Movement</h2>
            <p className="join-movement-text">
              Ready to make meaningful connections and unforgettable memories? ActivityBuddy is your gateway to a world of shared experiences. Join us today and be part of a community that values connection, passion, and togetherness.
            </p>
            <div className="button-container">
              <Link to="/login">
                <button className="become-button">Sign In</button>
              </Link>
            </div>
          </div>
        </section>
      ) : (
        null // Or <></> if you prefer an empty fragment
      )}
    </div >
      <Footer />
    </>
  );
};

export default About;
