import React, { useState, useEffect } from "react";
import "./BecomeAB.css";
import { auth } from "../components/firebaseConfig";
import { Checkbox, FormControlLabel, FormGroup, CircularProgress, TextField, MenuItem, FormControl } from '@mui/material';
import { CameraAlt, AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from "../components/GlobalStateContext";


import CurrencyInput from 'react-currency-input-field';
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";

const BecomeAB = () => {
    const { listingId } = useGlobalState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [disableForm, setDisableForm] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
    const [listingPhotos, setListingPhotos] = useState([]);
    const [rate, setRate] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [cityPlaceHolder, setCityPlaceHolder] = useState(true);
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [selectedGender, setSelectedGender] = useState("");
    const [genderPlaceHolder, setGenderPlaceholder] = useState(true);
    const [description, setDescription] = useState("");
    const [checkBoxStates, setCheckBoxStates] = useState({
        other: false,
        outdoors: false,
        food: false,
        fitness: false,
        arts: false,
        events: false,
        hobbies: false,
        night: false,
        online: false,
        safety: false
    });
    const [errors, setErrors] = useState({});
    const API_BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();


    useEffect(() => {
        const checkListingId = async () => {
            if (listingId) {
                navigate(`/listing/${listingId}`)
            }
        }
        checkListingId(); // Call the async function
    }, [listingId, navigate]);



    const scrollToForm = () => {
        document.getElementById("application-form").scrollIntoView({ behavior: "smooth" });
    };

    function validateDob(dob) {
        const dobPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/; // MM/DD/YYYY format
        return dobPattern.test(dob);
    }

    const handleRateChange = (value) => {
        setRate(value); // Value will be a string (with the dollar sign and decimals)
    };


    const handleDateChange = (e) => {
        let value = e.target.value;

        // Remove any non-numeric characters, except slashes
        value = value.replace(/[^0-9/]/g, '');

        setDateOfBirth(value);
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setCityPlaceHolder(false); // Ensure the placeholder doesn't reappear
    };

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
        setGenderPlaceholder(false); // Ensure the placeholder doesn't reappear
    };


    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckBoxStates((prevStates) => ({
            ...prevStates,
            [name]: checked,
        }));
    };

    const handleProfilePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a temporary URL for preview
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePhotoPreview(reader.result);  // Set the preview URL
            };
            reader.readAsDataURL(file);  // Convert file to base64 for preview

            setProfilePhoto(file);  // Store the actual file for submission
        }
    };

    const handleListingPhotoChange = (e) => {
        const files = Array.from(e.target.files);

        //filter out duplicates
        const uniqueFiles = files.filter((newFile) => {
            return !listingPhotos.some(
                (photo) => photo.file.name === newFile.name && photo.file.size === newFile.size
            );
        });

        const updatedPhotos = uniqueFiles.map((file) => {
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve({
                        file,  // Store the file for submission
                        preview: reader.result,  // Base64 preview URL
                    });
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(updatedPhotos).then((photos) => {
            setListingPhotos((prevPhotos) => [...prevPhotos, ...photos]);
        });
    };

    const handleDeleteListingPhoto = (id) => {
        setListingPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== id));
    };

    const validateForm = () => {
        const errors = {};
        const age = new Date().getFullYear() - new Date(dateOfBirth).getFullYear();
        const numericRate = parseFloat(rate.replace(/[^\d.-]/g, ''));

        if (!firstName) errors.firstName = "First name is required.";
        if (!lastName) errors.lastName = "Last name is required.";

        if (!selectedCity) errors.selectedCity = "City is required.";
        if (!selectedGender) errors.selectedGender = "Gender is required.";
        if (!rate) {
            errors.rate = "Hourly rate is required."
        } else if (numericRate < 15) {
            errors.rate = "Hourly rate must be at least $15."
        } else if (numericRate > 1000000) {
            errors.rate = "Too many characters in hourly rate field."
        }
        if (!dateOfBirth) errors.dateOfBirth = "Date of birth is required.";
        else if (!validateDob(dateOfBirth)) errors.dateOfBirth = "Use MM/DD/YYYY format for Date of birth.";
        else if (age < 18) errors.dateOfBirth = "You must be at least 18 years old.";
        else if (age > 120) errors.dateOfBirth = "You cannot be over 120 years old."

        // if (!rate) errors.rate = "Hourly rate is required.";
        if (!description) errors.description = "Listing description is required.";
        else if (description.length > 500) errors.description = "Listing description must be 500 characters or less.";
        if (!Object.values(checkBoxStates).includes(true)) errors.checkBoxStates = "At least one activity must be selected.";
        if (!profilePhoto) errors.profilePhoto = "Profile photo is required.";
        if (listingPhotos.length === 0) errors.listingPhotos = "At least one listing photo is required.";
        if (listingPhotos.length > 10) errors.listingPhotos = "Only 10 maximum listing photos allowed.";
        if (!agreedToTerms) errors.agreedToTerms = "You must agree to the terms and conditions.";


        setErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;
        setIsSubmitting(true);


        try {

            const formData = new FormData();
            formData.append('profilePhoto', profilePhoto);

            listingPhotos.forEach((photo) => {
                formData.append('listingPhotos', photo.file);
            });

            const data = {
                rate,
                firstName,
                lastName,
                selectedGender,
                selectedCity,
                dateOfBirth,
                description,
                checkBoxStates: JSON.stringify(checkBoxStates)
            };

            // Append other fields to formData if necessary
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            });
            const response = await fetch(`${API_BASE_URL}/api/user/become-activitybuddy`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
                    "Cache-Control": "no-cache, no-store, must-revalidate",
                    "Pragma": "no-cache",
                    "Expires": "0",
                },
                body: formData
            });
            const responseData = await response.json();
            if (!response.ok) {
                if (responseData.error === 'Email already registered as an ActivityBuddy.') {
                    setErrors({ email: 'Email already registered as an ActivityBuddy.' })
                    setIsSubmitting(false);
                    return
                } else {
                    setErrors({ email: 'An error occured, please try again later or contact us.' })
                    setDisableForm(true);
                    setIsSubmitting(false);
                    return
                }
            } else {
                if (responseData.accountLinkUrl) {
                    window.location.href = responseData.accountLinkUrl;
                }
            }
        } catch (error) {
            setIsSubmitting(false);
            if (error.code === 'auth/email-already-in-use') {
                setErrors({ email: 'Email already registered as an ActivityBuddy.' });
            } else {
                setDisableForm(true);
                setErrors({ email: 'An error occurred, please try again later or contact us.' });
            }
            return
        }
    };

    return (
        <>
            <Navbar onBuddyPage={true} />
            <div className="main-wrapper">
                <div className="become-ab-container">
                    {/* Header Section */}
                    <header>
                        <h1>Share Your Passion. Earn on Your Terms.</h1>
                        <p>
                            Become an ActivityBuddy and connect with people who share your interests while
                            getting paid for what you love to do.
                        </p>
                        <button className="start-app-button" onClick={scrollToForm}>Start your application</button>
                    </header>

                    {/* Why Become an ActivityBuddy */}
                    <section>
                        <h2 className='ab-h2'>Why Become an ActivityBuddy?</h2>
                        <div className="cards-container">
                            <div className="card">
                                <h3>Flexibility</h3>
                                <p>Enjoy the freedom to set your own schedule and host activities when it works for you.</p>
                                <div className="emoji">📅</div>
                            </div>
                            <div className="card">
                                <h3>Earn Money</h3>
                                <p>Earn money doing what you love by offering unique experiences to people who value your expertise.</p>
                                <div className="emoji">💵</div>
                            </div>
                            <div className="card">
                                <h3>Make Connections</h3>
                                <p>Build lasting relationships and inspire others through shared activities that make a difference.</p>
                                <div className="emoji">🤝</div>
                            </div>
                        </div>
                    </section>

                    {/* How It Works */}
                    <section>
                        <h2 className="how-it-works-header">How It Works</h2>
                        <div className="how-it-works-bullets">
                            <ol>
                                <li><strong>Sign Up</strong>: Complete the form below to create your profile and showcase what makes your activities special.</li>
                                <li><strong>Set Up Payments</strong>: After submitting your application, complete Stripe onboarding to securely set up your payments.</li>
                                <li><strong>Start Hosting</strong>: Your listing goes live! Start welcoming guests, sharing your passions, and building your own vibrant community.</li>
                            </ol>
                        </div>
                    </section>

                    {/* Become an ActivityBuddy Form */}
                    <section id="application-form">
                        <h2 className='ab-h2'>Become an ActivityBuddy</h2>

                        {/* Basic Information */}
                        <div className="form-section">
                            <h3>Basic Information</h3>
                            <div className="form-fields">

                                <input value={firstName} autoComplete="off" onChange={(e) => setFirstName(e.target.value)}
                                    type="text" className="input-field" placeholder="First Name" />
                                <input value={lastName} autoComplete="off" onChange={(e) => setLastName(e.target.value)}
                                    type="text" className="input-field" placeholder="Last Name" />
                                <FormControl fullWidth variant="outlined" className="select-form-control-ab">
                                    <TextField
                                        select
                                        id="city-select-ab"
                                        value={selectedGender}
                                        label={genderPlaceHolder ? "Gender" : ""}
                                        onChange={handleGenderChange}
                                        className="city-select-ab"
                                        size="small"
                                        InputLabelProps={{
                                            style: {
                                                color: 'white',
                                                marginTop: '15px',
                                                left: "50%",
                                                transform: "translateX(-50%)", // Center the label horizontally
                                                textAlign: "center",
                                            },
                                            shrink: false, // Prevents label from floating
                                        }}
                                    >
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </TextField>
                                </FormControl>
                                <FormControl fullWidth variant="outlined" className="select-form-control-ab">
                                    <TextField
                                        select
                                        id="city-select-ab"
                                        value={selectedCity}
                                        label={cityPlaceHolder ? "City" : ""}
                                        onChange={handleCityChange}
                                        className="city-select-ab"
                                        size="small"
                                        InputLabelProps={{
                                            style: {
                                                color: 'white',
                                                marginTop: '15px',
                                                left: "50%",
                                                transform: "translateX(-50%)", // Center the label horizontally
                                                textAlign: "center",
                                            },
                                            shrink: false, // Prevents label from floating
                                        }}
                                    >
                                        <MenuItem value="San Francisco, CA">San Francisco, CA</MenuItem>
                                        <MenuItem value="Seattle, WA">Seattle, WA</MenuItem>
                                        <MenuItem value="Miami, FL">Miami, FL</MenuItem>
                                        <MenuItem value="Tampa, FL">Tampa, FL</MenuItem>
                                    </TextField>
                                </FormControl>
                                <p className="more-cities-text">More cities coming soon.</p>
                            </div>
                        </div>

                        {/* Listing Details */}
                        <div className="form-section">
                            <h3>Listing Details</h3>
                            <div className="form-fields">
                                <CurrencyInput
                                    name="hourly-rate"
                                    className="input-field hourly-rate"
                                    placeholder="Hourly Rate"
                                    value={rate}
                                    allowDecimals={false}
                                    prefix="$" // Dollar sign prefix
                                    suffix="/hour"
                                    onValueChange={handleRateChange} // Handle value change
                                />
                                <input type="text" className="input-field" placeholder="Date of Birth (MM/DD/YYYY)"
                                    onChange={handleDateChange}
                                    value={dateOfBirth}
                                />
                            </div>
                            <p className="description-guidance">
                                In 500 characters or less, describe your listing. This is your chance to
                                showcase your personality and what you offer.
                            </p>
                            <div className="ab-description-container">
                                <span className="edit-char-counter">{description.length}/500</span>
                                <textarea className="input-field" value={description} onChange={(e) => setDescription(e.target.value)} placeholder={`Hi! I'm ${firstName || '[Your Name]'}, your ActivityBuddy. Let's make our time together fun and memorable! Whether it's hiking, exploring new spots, or trying out activities, I'm open to your ideas. Let's get creative and enjoy new experiences together!`}></textarea>
                            </div>
                        </div>

                        {/* Supported Activities */}
                        <div className="form-section">
                            <h3 className="form-header">Choose your Supported Activities</h3>
                            <div className="checkbox-container">
                                <FormGroup className="checkbox-group">
                                    <FormControlLabel
                                        control={<Checkbox name="outdoors" color="default" checked={checkBoxStates.outdoors} onChange={handleCheckboxChange} />}
                                        label="Outdoors: Hike, bike, and explore nature."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="food" color="default" checked={checkBoxStates.food} onChange={handleCheckboxChange} />}
                                        label="Food & Drink: Discover new restaurants and bars."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="fitness" color="default" checked={checkBoxStates.fitness} onChange={handleCheckboxChange} />}
                                        label="Fitness & Sports: Work out, do yoga, and play sports."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="arts" color="default" checked={checkBoxStates.arts} onChange={handleCheckboxChange} />}
                                        label="Arts & Culture: Visit museums, theaters, and art events."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="events" color="default" checked={checkBoxStates.events} onChange={handleCheckboxChange} />}
                                        label="Events: Attend local festivals and gatherings."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="hobbies" color="default" checked={checkBoxStates.hobbies} onChange={handleCheckboxChange} />}
                                        label="Hobbies: Share interests like gardening or photography."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="night" color="default" checked={checkBoxStates.night} onChange={handleCheckboxChange} />}
                                        label="Nightlife: Enjoy clubs, bars, and late-night events."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="online" color="default" checked={checkBoxStates.online} onChange={handleCheckboxChange} />}
                                        label="Online: Connect through gaming and video chats."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="safety" color="default" checked={checkBoxStates.safety} onChange={handleCheckboxChange} />}
                                        label="Safety: Provide company for safe walks and outings."
                                    />
                                </FormGroup>
                            </div>
                        </div>

                    </section>
                    {/* Photos Section */}
                    <div className="photos-section">
                        <h3>Photos</h3>
                        <p className="subtext">Upload your profile photo</p>

                        <div className="profile-photo-upload">
                            <div className="photo-circle">
                                {profilePhotoPreview ? (
                                    <img src={profilePhotoPreview} alt="Profile" className="profile-photo-preview" />
                                ) : (
                                    <AccountCircle className="photo-icon" fontSize="large" />
                                )}
                            </div>
                            <label className="upload-button">
                                <CameraAlt className="camera-icon" />
                                <span>Upload</span>
                                <input
                                    type="file"
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={handleProfilePhotoChange}
                                    className="file-input"
                                />
                            </label>
                        </div>

                        <p className="subtext">Upload your listing photos</p>
                        <div className="listing-photos-upload">
                            <label className="upload-button-label">
                                <input
                                    type="file"
                                    multiple
                                    accept="image/jpeg, image/jpg, image/png"
                                    className="image-upload-input"
                                    onChange={handleListingPhotoChange}
                                />
                                Choose Photos
                            </label>
                            <p className="file-count">
                                {listingPhotos.length} {listingPhotos.length === 1 ? 'photo' : 'photos'} selected
                            </p>
                            <p className="upload-instructions">
                                Upload multiple images. Images will be cropped to a vertical orientation.
                            </p>
                            <div className="listing-photos-preview">
                                {listingPhotos.map((photo) => (
                                    <div key={photo.id} className="photo-preview">
                                        <img src={photo.preview} alt="Listing" />
                                        <button
                                            className="delete-button"
                                            onClick={() => handleDeleteListingPhoto(photo.id)}
                                        >
                                            ✕
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="form-divider"></div>
                    {/* Error Messages */}
                    {Object.keys(errors).length > 0 && (
                        <div className="error-messages">
                            {Object.values(errors).map((error, index) => (
                                <p key={index} className="error">{error}</p>
                            ))}
                        </div>
                    )}
                    {/* Terms and Conditions */}
                    <div className="terms-section">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="default"
                                    checked={agreedToTerms}
                                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                                />
                            }
                            label={
                                <span>
                                    I agree to the{' '}
                                    <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                        Terms and Conditions
                                    </a>{' '}
                                    and{' '}
                                    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                        Privacy Policy
                                    </a>
                                </span>
                            }
                        />
                    </div>
                    <button type="submit" disabled={isSubmitting || disableForm} onClick={handleSubmit} className="submit-button">
                        {isSubmitting ? <CircularProgress color="inherit" /> : 'Submit Application'}
                        {isSubmitting ? <p>Please wait</p>: ''}
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );

};

export default BecomeAB;
