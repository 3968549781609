import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './components/authContext';
import Home from './pages/Home';
import BecomeActivityBuddy from './pages/BecomeAB';
import SignInModal from './components/SignInModal';
import About from './pages/About';
import Support from './pages/Support';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import Listing from './pages/Listing';
import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './components/PrivateRoute';
import ReactGA from 'react-ga4';
import EditListing from './pages/EditListing';
import BookingPage from './pages/BookingPage';
import Activities from './pages/Activities';
import Activity from './pages/Activity';
import Messages from './pages/Messages';
import MessageThread from './pages/MessageThread';
import CustomSignUp from './components/CustomSignUp';
import Profile from './pages/Profile';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with your public key
const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(STRIPE_KEY);
const AppRouter = () => {

  const { user } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();


  return (
    <>
      <ScrollToTop />
      <AnalyticsWrapper>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/become-activitybuddy" element={<PrivateRoute element={<BecomeActivityBuddy />} />} />
        <Route path="/login" element={<SignInModal />} />
        {/* <Route path="/custom-sign-up" element={<CustomSignUp />} /> */}
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Support />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/listing/:id" element={<Listing />} />
        <Route path="/editlisting/:id" element={<PrivateRoute element={<EditListing />} />} />
        <Route path="/booking/:id" element={<PrivateRoute element={<BookingPage />} />} />
        <Route path="/activities" element={<PrivateRoute element={<Activities />} />} />
        <Route path="/activity/:id" element={<PrivateRoute element={<Activity />} />} />
        <Route path="/messages" element={<PrivateRoute element={<Messages />} />} />
        <Route path="/message/:id" element={<PrivateRoute element={<MessageThread />} />} />
        <Route path="/profile/:id" element={<PrivateRoute element={<Profile />} />} />
        <Route path="*" element={<Home />} />
      </Routes>
      </AnalyticsWrapper>
    </>
  );
};


function AnalyticsWrapper({ children }) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return children;
}

const App = () => (
  <Router>
    <Elements stripe={stripePromise}>
    <AppRouter />
    </Elements>
  </Router>
)

export default App;
