import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import "./Messages.css";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import AccountCircle from '@mui/icons-material/AccountCircle';

const Messages = () => {
    const navigate = useNavigate();
    const [messageThreads, setMessageThreads] = useState([]);
    const [maxLength, setMaxLength] = useState(window.innerWidth < 950 ? 20 : 60);


    const API_BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchMessageThreads = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                navigate("/login");
                return;
            }
            const token = await user.getIdToken();
            try {
                const response = await fetch(`${API_BASE_URL}/message/all`, {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.ok) {
                    const data = await response.json();
                    setMessageThreads(data.formattedThreads);
                } else {
                    console.error("Failed to fetch message threads.");
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchMessageThreads();
    }, [navigate]);

    useEffect(() => {
        const handleResize = () => {
            setMaxLength(window.innerWidth < 768 ? 20 : 60);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
    }, []);

    const truncateMessage = (message) => {
        return message.length > maxLength ? message.substring(0, maxLength) + "..." : message;
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const today = new Date();

        if (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        ) {
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        }
        return date.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
    };
    return (
        <>
        <Navbar />

        <div className="messages-container">
            <header className="messages-header">Messages</header>
            <div className="messages-thread-list">
                {messageThreads.map(thread => (
                    <div
                        key={thread.thread_id}
                        className="messages-thread"
                        onClick={() => navigate(`/message/${thread.thread_id}`)}
                    >
                        {thread.unread_status && <div className="unread-indicator"></div>}
                        <div className="messages-thread-info">
                            {thread.profile_photo ? (
                                <img
                                    src={thread.profile_photo}
                                    alt="Profile"
                                    className="messages-profile-pic"
                                />
                            ) : (
                                <AccountCircle className="messages-profile-icon" />
                            )}
                            <div className="messages-thread-details">
                                <span className="messages-thread-name">{thread.first_name}</span>
                                 <span className={`messages-thread-last ${thread.unread_status ? "unread" : ""}`}>
                                    {truncateMessage(thread.last_message)}
                                </span>
                            </div>
                        </div>
                        <span className={`messages-thread-time ${thread.unread_status ? "unread" : ""}`}>
                            {formatDate(thread.created_at)}
                            </span>
                    </div>
                ))}
            </div>
        </div>
        </>
    );
};

export default Messages;
