import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './authContext'; // Import the AuthContext for the user
const API_BASE_URL = process.env.REACT_APP_BASE_URL;

// Create Context
const GlobalStateContext = createContext();

// Custom hook to use global state
export const useGlobalState = () => useContext(GlobalStateContext);

// GlobalStateProvider component
export const GlobalStateProvider = ({ children }) => {
  const { user } = useAuth(); // Use the Auth context to get the user
  const [listingId, setListingId] = useState(null);
  const [profileId, setProfileId] = useState(null);
  const [unreadMessagesIndicator, setUnreadMessagesIndicator] = useState(false);

  // Fetch listingId and profileId when user is available
  useEffect(() => {
    const fetchListingId = async () => {
      if (user) {
        try {
          const token = await user.getIdToken();
          const response = await fetch(`${API_BASE_URL}/api/user/listingid`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          setUnreadMessagesIndicator(data.unreadMessages)
          if (data?.listingId) {
            setListingId(data.listingId);
          }
          if (data?.profileId) {
            setProfileId(data.profileId);
          }
        } catch (error) {
          console.error('Error fetching listing ID:', error);
        }
      }
    };

    if (user) {
      fetchListingId(); // Fetch data only if a user is logged in
    }
  }, [user]); // Re-run when the user changes

  return (
    <GlobalStateContext.Provider value={{ listingId, profileId, unreadMessagesIndicator }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
