import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormControl, InputLabel, Select, MenuItem, TextField, CircularProgress } from "@mui/material";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import { DateTime } from 'luxon';
import { getAuth } from "firebase/auth";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import "./BookingPage.css";
import Navbar from "../components/NavBar";
import { loadStripe } from "@stripe/stripe-js";


const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const FLORIDA_TAX_RATE = 0.07;
const stripePromise = loadStripe(STRIPE_KEY);

const BookingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(addDays(new Date(), 1));
  const [startTime, setStartTime] = useState("12:30PM");
  const [endTime, setEndTime] = useState("01:30PM");
  const [priceDetails, setPriceDetails] = useState({
    baseRate: 0,
    serviceFee: 0,
    taxes: 0,
    total: 0,
  });
  const [description, setDescription] = useState("");
  const [fetchedListing, setFetchedListing] = useState(null);
  const [paymentError, setPaymentError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false);


  const stripe = useStripe();
  const elements = useElements();

  const listing = location.state?.listing;

  const parseTime = (timeStr) => {
    // Time format is expected to be something like '12:30PM' or '01:30AM'
    const regex = /(\d{1,2}):(\d{2})(AM|PM)/;
    const match = timeStr.match(regex);

    if (!match) {
      console.error("Invalid time format:", timeStr);
      return NaN; // Return NaN if the time string doesn't match the expected format
    }

    let [_, hours, minutes, modifier] = match;
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    // Convert the 12-hour format to 24-hour format
    if (modifier === "PM" && hours !== 12) hours += 12;
    if (modifier === "AM" && hours === 12) hours = 0;

    return hours + minutes / 60;
  };

  const getNextAvailableTime = () => {
    const now = new Date();
    const nowET = DateTime.fromJSDate(now).setZone('America/New_York');
    let nextAvailable = nowET.plus({ hours: 4 });
    const minutes = nextAvailable.minute;

    if (minutes < 30) {
      nextAvailable = nextAvailable.set({ minute: 30, second: 0, millisecond: 0 });
    } else {
      nextAvailable = nextAvailable.plus({ hours: 1 }).set({ minute: 0, second: 0, millisecond: 0 });
    }

    return nextAvailable;
  };

  const generateTimeOptions = () => {
    const times = [];
    const now = new Date();
    if (!selectedDate || isNaN(new Date(selectedDate).getTime())) {
      return [];
    }
    const selectedDateET = DateTime.fromJSDate(new Date(selectedDate)).setZone('America/New_York');
    const nowET = DateTime.fromJSDate(now).setZone('America/New_York');

    const isToday = selectedDateET.toISODate() === nowET.toISODate();
    const earliestSelectableTime = isToday ? getNextAvailableTime() : null;

    for (let hour = 0; hour < 24; hour++) {
      ["00", "30"].forEach((minutes) => {
        const dateTime = new Date(selectedDate);
        dateTime.setHours(hour, parseInt(minutes), 0, 0);

        if (isToday && dateTime < earliestSelectableTime.toJSDate()) return;

        const formattedTime = format(dateTime, "hh:mma");
        if (!times.includes(formattedTime)) {
          times.push(formattedTime);
        }
      });
    }


    return times;
  };


  const handleStartTimeChange = (e) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);

    const newEndTime = getAdjustedEndTime(newStartTime);
    setEndTime(newEndTime);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  const getAdjustedEndTime = (startTime) => {
    const start = parseTime(startTime);
    let newEnd = start + 1; // Default 1-hour duration
    if (newEnd >= 24) {
      return "12:00AM (next day)"; // Return midnight when it's past 11:59 PM
    }

    const newDate = new Date();
    newDate.setHours(Math.floor(newEnd), (newEnd % 1) * 60, 0, 0);
    return format(newDate, "hh:mma");  };

  const handleDateChange = (date) => {
    setSelectedDate(date);


    // If the selected date is today, preselect the start time to the next available time
    if (date.toDateString() === new Date().toDateString()) {
      const nextAvailableTime = getNextAvailableTime();
      const nextAvailableDate = nextAvailableTime.toJSDate();
      const formattedNextTime = format(nextAvailableDate, "hh:mma");
      setStartTime(formattedNextTime);
      setEndTime(getAdjustedEndTime(formattedNextTime));
    }

  };

  useEffect(() => {
    if (!listing) {
      navigate(`/listing/${id}`);
    } else {
      setFetchedListing(listing);
    }
  }, [id, listing, navigate]);

  useEffect(() => {
    if (startTime && endTime && fetchedListing) {
      calculateTotal();
    } else {
      setPriceDetails({ baseRate: 0, serviceFee: 0, taxes: 0, total: 0 });
    }
  }, [startTime, endTime, selectedDate, fetchedListing]);


  const calculateTotal = () => {
    if (!startTime || !endTime || !fetchedListing) {
      setPriceDetails({ baseRate: 0, serviceFee: 0, taxes: 0, total: 0 });
      return;
    }
    let start = parseTime(startTime);
    let end = parseTime(endTime);

    if (endTime === "12:00AM (next day)") {
      end = 24;
    }

    if (end < start) {
      end += 24;  // Treat end time as past midnight
    }

    const duration = Math.max(end - start, 0);

    const baseRate = parseFloat((fetchedListing.hourly_price * duration).toFixed(2)) || 0;
    const serviceFee = parseFloat((baseRate * 0.15).toFixed(2));
    const taxes = parseFloat(((serviceFee) * FLORIDA_TAX_RATE).toFixed(2));
    const total = parseFloat((baseRate + serviceFee + taxes).toFixed(2));

    setPriceDetails({ baseRate, serviceFee, taxes, total });
  };

  const generateEndTimeOptions = () => {
    const times = generateTimeOptions();
    const startParsed = parseTime(startTime);

    // Filter the times based on whether they come after the start time
    const endTimes = times.filter((time) => {
      const endParsed = parseTime(time);
      return endParsed > startParsed; // Only keep times after the start time
    });

    // Add "12:00AM (next day)" to the list if it hasn't been added already
    if (!endTimes.includes("12:00AM (next day)")) {
      endTimes.push("12:00AM (next day)");
    }

    return endTimes;
  };

  const handleBookingSubmit = async () => {
    setIsSubmitting(true);
    setPaymentError(null);
    if (!selectedDate || !startTime || !endTime || !description) {
      setPaymentError("All fields are required.");
      setIsSubmitting(false)
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setPaymentError("Payment field is missing.");
      setIsSubmitting(false)
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      navigate('/login')
      setIsSubmitting(false)
      return;
    }
    const token = await user.getIdToken();
    if (!token) {
      navigate('/login')
      setIsSubmitting(false)
      return;
    }

    // Get the user's local timezone offset (in minutes)
    const userOffset = selectedDate.getTimezoneOffset();
  
    // Florida's time zone offset (Eastern Time -5 hours, but we calculate it dynamically)
    const floridaOffset = new Date(selectedDate).toLocaleString("en-US", { timeZone: "America/New_York" });
    const floridaDate = new Date(floridaOffset);
    const floridaTimezoneOffset = floridaDate.getTimezoneOffset();
  
    // Convert the selected date to Florida time by adjusting for the offset difference
    const floridaTime = new Date(selectedDate.getTime() + (userOffset - floridaTimezoneOffset) * 60000);
  
    const floridaTimeUTC = floridaTime.toISOString();

  
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setPaymentError("Payment error");
        setIsSubmitting(false)
        return;
      } 

      const activityResponse = await fetch(`${API_BASE_URL}/activity/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "Pragma": "no-cache",
          "Expires": "0",
        },
        body: JSON.stringify({
          listingId: id,
          date: floridaTimeUTC,
          startTime,
          endTime,
          description,
          paymentMethodId: paymentMethod.id,
          amount: Math.round(priceDetails.total * 100),
        }),
      });

      const activityData = await activityResponse.json();

      if (!activityResponse.ok) {
        setPaymentError("Activity creation and payment failed. If you keep seeing this message contact support.");
        setIsSubmitting(false)
        return;
      }


      navigate(`/activity/${activityData.id}`);

    } catch (error) {
      setPaymentError(`Booking failed: ${error.message}`);
      setIsSubmitting(false);
      return;
    }
  };


  return (
    <>
      <Navbar />
      <div className="booking-container">
        <h2 className="booking-header">Request to book</h2>
        {fetchedListing && (
          <>
            <img src={fetchedListing.profile_photo} alt="Profile" className="profile-image" />
            <h3 className="subheader-booking">Your activity with {fetchedListing.first_name}</h3>
          </>
        )}
        <h4 className="subsubheader-booking">When</h4>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className="custom-datepicker-container"
            label="Date"
            value={selectedDate}
            onChange={handleDateChange}
            minDate={new Date()}
            renderInput={(params) => <TextField {...params} fullWidth className="custom-datepicker" />}
          />
        </LocalizationProvider>
        <FormControl fullWidth className="custom-time-form-control">
          <InputLabel className="custom-time-label">Start Time</InputLabel>
          <Select value={startTime} onChange={handleStartTimeChange} className="custom-time-select">
            {generateTimeOptions().map((time, index) => (
              <MenuItem key={index} value={time}>{time}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth className="custom-time-form-control">
          <InputLabel className="custom-time-label">End Time</InputLabel>
          <Select value={endTime} onChange={handleEndTimeChange} className="custom-time-select">
            {generateEndTimeOptions().map((time, index) => (
              <MenuItem key={index} value={time}>{time}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {parseTime(startTime) >= 18 && (
          <p className="booking-time-past-midnight">If your activity goes past midnight, make another booking request for the next day.</p>
        )}
        <h4 className="subsubheader-booking">Describe your activity</h4>
        <p className="activity-description">Use as much detail as possible including the exact location of the activity. You are responsible for all activity costs.</p>
        <TextField
          multiline fullWidth rows={4}
          placeholder="Hello, can we meet for a coffee hangout on 2nd ave?"
          value={description} onChange={(e) => setDescription(e.target.value)}
        />
        <h4 className="subsubheader-booking">Add new payment</h4>
        <div style={{ width: '104%', marginLeft: '-2%' }}>
          <CardElement
            options={{
              style: {
                base: {
                  color: 'white',  // Change the text color here
                  fontSize: '16px',
                  height: '40px', // Adjust height to prevent it from being too skinny
                  lineHeight: '20px',
                  iconColor: 'white',
                  width: '100%',
                },
              },
            }}
          />
        </div>
        <h4 className="subsubheader-booking">Price details</h4>
        <div className="price-details">
          <span>${fetchedListing?.hourly_price} x {priceDetails.baseRate / (fetchedListing?.hourly_price || 1)} hours</span>
          <span>${priceDetails.baseRate.toFixed(2)}</span>
        </div>
        <div className="price-details">
          <span>Service fee</span>
          <span>${priceDetails.serviceFee.toFixed(2)}</span>
        </div>
        <div className="price-details">
          <span>Taxes</span>
          <span>${priceDetails.taxes.toFixed(2)}</span>
        </div>
        <div className="price-total">
          <span>Total (USD)</span>
          <span>${priceDetails.total.toFixed(2)}</span>
        </div>
        <p className="refund-warning-booking">If your request is declined, you will receive a full refund. Both you and the host have up to 24 hours before the scheduled activity to cancel the request without any charges being applied.</p>
        {paymentError && <p className="booking-error-message">{paymentError}</p>}
        <button onClick={handleBookingSubmit} disabled={isSubmitting} className="request-button">
          {isSubmitting ? <CircularProgress color="inherit" /> : "Request to book"}
        </button>
      </div>
    </>
  );
};

export default BookingPage;
