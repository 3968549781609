import React, { useEffect, useState, useRef } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import "./MessageThread.css";
import ReactMarkdown from "react-markdown";
import { AccountCircle, ArrowBackIos, Send } from '@mui/icons-material';
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";



const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const SOCKET_SERVER_URL = API_BASE_URL;

const MessageThread = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [messageDetails, setMessageDetails] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [socket, setSocket] = useState(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const fetchTokenAndUser = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                navigate('/login');
                return null;
            }
            const token = await user.getIdToken();
            return { user, token };
        };

        const fetchMessageThread = async (token) => {
            try {
                const response = await fetch(`${API_BASE_URL}/message/${id}`, {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.ok) {
                    const data = await response.json();
                    setMessageDetails(data);
                } else {
                    console.error("Failed to fetch message details.");
                }
            } catch (error) {
                console.error(error);
            }
        };

        const setup = async () => {
            const authData = await fetchTokenAndUser();
            if (!authData) return;

            const { token } = authData;
            fetchMessageThread(token); // Fetch the thread details
        };

        setup();
    }, [id, navigate]); // Only fetch thread details when 'id' or 'navigate' changes

    useEffect(() => {
        const initializeSocket = (token) => {
            const newSocket = io(SOCKET_SERVER_URL, {
                query: { token }
            });

            setSocket(newSocket);

            newSocket.emit("joinThread", id);

            newSocket.on("newMessage", (message) => {
                // This ensures that the socket message is correctly added to state
                setMessageDetails((prevDetails) => {
                    if (prevDetails && prevDetails.messages) {
                        return {
                            ...prevDetails,
                            messages: [...prevDetails.messages, message], // Append new message
                        };
                    }
                    return prevDetails; // If no messages exist yet, just return the prevDetails
                });
            });

            return newSocket;
        };


        const setupSocket = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const token = await user.getIdToken();
                const newSocket = initializeSocket(token);
                return newSocket;
            }
        };

        setupSocket().then((newSocket) => {
            setSocket(newSocket); // Save the socket instance to state

            // Cleanup on unmount or dependency change
            return () => {
                if (newSocket) newSocket.disconnect();
            };
        });

    }, [id]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messageDetails?.messages]);

    const handleBack = () => {
        navigate('/messages');
    };

    const renderMessages = () => {
        if (!messageDetails || !messageDetails.messages) return null;

        const messages = messageDetails.messages;
        const groupedMessages = groupMessagesByDate(messages);  // Helper function to group messages by date

        return groupedMessages.map((group, index) => (
            <div key={index}>

                {group.date && <div className="message-date">{group.date}</div>}
                {group.messages.map((msg, i) => (
                    <div key={i} className={`message ${msg.isSentByCurrentUser ? "sent" : "received"}`}>
                        {msg.sender && !msg.isSentByCurrentUser && ( // Only show sender info for received messages
                            <div className="sender-info">
                                {msg.sender.activitybuddy_info?.profile_photo ? (
                                    <img
                                        src={msg.sender.activitybuddy_info.profile_photo}
                                        alt="Profile"
                                        className="message-profile-pic-left"
                                    />
                                ) : (
                                    <AccountCircle className="message-profile-pic-left-placeholder" />
                                )}
                            </div>
                        )}
                        <div className="message-time">{formatTime(msg.created_at)}</div>
                        <div className="message-content">
                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                        </div>

                    </div>
                ))}
            </div>
        ));
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZone: "America/New_York"
        });
    };

    const groupMessagesByDate = (messages) => {
        const grouped = [];
        let currentDate = null;

        messages.forEach((msg) => {
            const messageDate = new Date(msg.created_at).toLocaleDateString();

            if (messageDate !== currentDate) {
                currentDate = messageDate;
                grouped.push({ date: currentDate, messages: [msg] });
            } else {
                grouped[grouped.length - 1].messages.push(msg);
            }
        });

        return grouped;
    };

    const sendMessage = async () => {
        if (!newMessage.trim()) return;

        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) return;

        const token = await user.getIdToken();

        const messageData = {
            thread_id: id,
            messageText: newMessage,
        };

        try {
            const response = await fetch(`${API_BASE_URL}/message/send`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(messageData),
            });

            if (response.ok) {
                setNewMessage(""); // Clear input field
            } else {
                console.error("Failed to send message.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getOtherParticipant = (messages) => {
        if (!messages || messages.length === 0) return null;

        const firstMessage = messages[0];

        return firstMessage.isSentByCurrentUser
            ? firstMessage.recipient
            : firstMessage.sender;
    };

    const otherParticipant = messageDetails?.messages ? getOtherParticipant(messageDetails.messages) : null;


    return (
        <>
            <div className="message-thread">
                <div className="message-header">
                    <ArrowBackIos onClick={handleBack} fontSize="large" className="message-back-button" />
                    {messageDetails?.messages?.length > 0 && (
                        <div className="header-profile">
                            {otherParticipant?.activitybuddy_info?.profile_photo ? (
                                <img
                                    src={otherParticipant.activitybuddy_info.profile_photo}
                                    alt="Profile"
                                    className="message-profile-pic"
                                />
                            ) : (
                                <AccountCircle className="message-profile-pic-placeholder" />
                            )}
                            <div className="message-header-first-name">{otherParticipant?.first_name}</div>
                        </div>
                    )}
                </div>

                <div className="message-body">
                    {renderMessages()}
                    <div ref={messagesEndRef} />
                </div>

                <div className="message-input">
                    <input
                        type="text"
                        placeholder={messageDetails?.canSendMessage ? 'Write a message' : 'Activity is cancelled or completed'}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        disabled={!messageDetails?.canSendMessage} // Disable input if the user can't send messages
                    />
                    {newMessage.trim().length > 0 && messageDetails?.canSendMessage && ( // Only show the send button if the user can send messages
                        <button onClick={sendMessage}>
                            <Send className="send-message-icon" fontSize="medium" />
                        </button>
                    )}
                </div>
            </div>
        </>

    );
};


export default MessageThread;
