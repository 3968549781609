import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth } from "../components/firebaseConfig";
import { useAuth } from "../components/authContext";
import { useNavigate, useLocation } from 'react-router-dom';
import { useGlobalState } from "./GlobalStateContext";

import "./NavBar.css";
import SignInModal from "./SignInModal";

const Navbar = ({ onBuddyPage = false }) => {
    const { listingId, profileId, unreadMessagesIndicator } = useGlobalState();
    const [menuActive, setMenuActive] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [signingOut, setSigningOut] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const API_BASE_URL = process.env.REACT_APP_BASE_URL;



    const { user, signOut } = useAuth();


    const toggleMenu = () => {
        setMenuActive((prevState) => !prevState); // Toggle the menu state
    };

    const handleSignInClick = () => {
        // Store the current path in localStorage
        localStorage.setItem('redirectPath', location.pathname);
        navigate('/login'); // Go to the login page
        setIsModalOpen(true); // Open the modal
    };

    const closeModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    useEffect(() => {
        if (!user) {
            setSigningOut(false); // Ensure button resets when user is null
        }
    }, [user]);

    const signOutToHome = async () => {
        try {
            await signOut(); // Sign out the user
            navigate('/'); // Navigate to home page after sign-out
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const toggleDropdown = () => {
        setProfileDropdown((prev) => !prev);
    };
    

    const logo = "https://activitybuddyimages.s3.us-east-2.amazonaws.com/static_images/ab_logo.png";



    return (
        <>
            <nav className="navbar">
                {/* Logo Section */}
                <Link className='navbar-left' to="/">
                    <img src={logo} alt="ActivityBuddy Logo" className="navbar-logo" />
                    <span className="navbar-title">ActivityBuddy</span>
                </Link>


                {/* Hamburger Menu Icon */}
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="line" tabIndex="-1"></div>
                    <div className="line" tabIndex="-1"></div>
                    <div className="line" tabIndex="-1"></div>
                </div>

                {/* Links Section */}
                <ul className={`navbar-links ${menuActive ? "active" : ""}`}> {/* Toggle active class */}
                    <li>
                        <Link to="/contact-us">Support</Link>
                    </li>
                    <li>
                        <Link to="/about-us">About Us</Link>
                    </li>
                    {user ? (
                        <>
                            <li>
                                <Link to="/activities">My Activities</Link>
                            </li>
                            <li className="profile-menu">
                                {/* Profile Dropdown */}
                                <span className="profile-label" onClick={toggleDropdown}>
                                {unreadMessagesIndicator && <div className="nav-unread-indicator-main"></div>}
                                    Profile
                                    <span className={`dropdown-icon ${profileDropdown ? "active" : ""}`}>▼</span>
                                </span>
                                {profileDropdown && (
                                    <ul
                                        className={`dropdown-menu ${profileDropdown ? "show" : ""} ${window.innerWidth <= 768 ? "always-visible" : ""}`}
                                    >
                                        <li>
                                            {profileId && <Link to={`/profile/${profileId}`} >Profile</Link>}
                                        </li>
                                        <li>
                                            <Link
                                                to={listingId ? `/listing/${listingId}` : "/become-activitybuddy"}
                                            >
                                                {listingId ? "My Listing" : "Become an ActivityBuddy"}
                                            </Link>
                                        </li>
                                        <li>
                                        {unreadMessagesIndicator && <div className="nav-unread-indicator-sub"></div>}
                                            <Link to="/messages">Messages</Link>
                                        </li>
                                        <li>
                                            <button onClick={signOutToHome}>Sign Out</button>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        </>
                    ) : (
                        <>
                            <li>
                                <Link to="/login" onClick={handleSignInClick}>Sign In</Link>
                            </li>
                            {!onBuddyPage && (
                                <li>
                                    <Link to={listingId ? `/listing/${listingId}` : "/become-activitybuddy"} className="navbar-button">
                                        Become an ActivityBuddy
                                    </Link>
                                </li>
                            )}
                        </>
                    )}
                </ul>
            </nav>
            {/* Sign-In Modal */}
            {isModalOpen && <SignInModal />}
        </>
    );
};

export default Navbar;
