import React, { useState, useEffect } from "react";
import { auth } from "../components/firebaseConfig";
import { fetchSignInMethodsForEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import { useAuth } from "../components/authContext";
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./SignInModal.module.css";
import Navbar from "./NavBar";

const SignInModal = () => {
    const [step, setStep] = useState(1); // Step in the modal flow
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [resetConfirmation, setResetConfirmation] = useState(false);

    const [error, setError] = useState("");
    const [loadingSignUp, setLoadingSignUp] = useState(false);
    const [signUpErrors, setSignUpErrors] = useState({});
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const API_BASE_URL = process.env.REACT_APP_BASE_URL;




    const validateSignUp = () => {
        const errors = {};

        if (!firstName) errors.firstName = "First name is required.";
        if (!email) {
            errors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Please enter a valid email.";
        }
        if (!password) {
            errors.password = "Password is required.";
        } else if (password !== confirmPassword) {
            errors.password = "Password and confirm password need to match.";
        } else if (password.length < 8) {
            errors.password = "Password must be at least 8 characters.";
        } else if (!/[A-Za-z]/.test(password) || !/[0-9]/.test(password)) {
            errors.password = "Password must contain at least one letter and one number.";
        };
        if (!agreedToTerms) errors.agreedToTerms = "You must agree to the terms and conditions.";


        setSignUpErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };

    const handleEmailSubmit = async (e) => {
        if (e?.key !== "Enter" && e?.type !== "click") return;
        try {
            const methods = await fetchSignInMethodsForEmail(auth, email);
            if (methods.length > 0) {
                setStep(2); // Proceed to password input
            } else {
                setStep(3); // Redirect to sign-up
            }
        } catch (err) {
            setError('Server error, please contact support.');
        }
    };
    const handleSignIn = async (e) => {
        if (e?.key !== "Enter" && e?.type !== "click") return;
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
                    if (firebaseUser) {
                        // Retrieve the stored redirect location
                        const redirectPath = localStorage.getItem('redirectPath') || '/'; // Default to '/' if no redirect path
                        localStorage.removeItem('redirectPath'); // Clear the stored path after use
                        navigate(redirectPath); // Redirect user to the intended page
                        unsubscribe();
                    }
                });
            })
            .catch((err) => {
                console.error("Sign-in error:", err);
                setError('Incorrect email or password.');
            });
    };

    const handleForgotPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            setResetConfirmation(true);
        } catch (error) {
            console.error('Error sending reset email:', error);
        }
    }

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!validateSignUp()) return;
        setLoadingSignUp(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const firebaseUserId = userCredential.user.uid;
            const data = {
                email,
                firstName,
                firebaseUserId
            }
            const response = await fetch(`${API_BASE_URL}/api/user/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache, no-store, must-revalidate",
                    "Pragma": "no-cache",
                    "Expires": "0",
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                setSignUpErrors({ email: 'An error occured, please try again later or contact us.' })
            }
            const redirectPath = localStorage.getItem('redirectPath') || '/';
            localStorage.removeItem('redirectPath');
            navigate(redirectPath);
        } catch (err) {
            setSignUpErrors({ email: 'An error occured, please try again later or contact us.' })
        } finally {
            setLoadingSignUp(false);
        }
    };

    return (
        <>
            <div className={styles['modal-overlay']}>
                <div className={styles['modal']}>
                    {step === 1 && (
                        <>
                            <h2 className={styles['sign-in-header']}>Sign In</h2>
                            <input
                                type="email"
                                className={styles['login-input']}
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={handleEmailSubmit}
                            />
                            <button className={styles['submission-button']} onClick={handleEmailSubmit}>Continue</button>
                            {error && <p className={styles['error']}>{error}</p>}
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <h2 className={styles['sign-in-header']}>Welcome Back!</h2>
                            <input
                                type="email"
                                className={styles['login-input']}
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                type="password"
                                className={styles['login-input']}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={handleSignIn}
                            />
                            <button onClick={handleForgotPassword} disabled={resetConfirmation} className={styles['forgot-password-login']}>Forgot password?</button>
                            {resetConfirmation && (
                                <p className={styles['password-reset-text']}>A password reset email has been sent.</p>
                            )}
                            <button className={styles['submission-button']} onClick={handleSignIn}>Sign In</button>
                            {error && <p className={styles['error']}>{error}</p>}
                        </>
                    )}
                    {step === 3 && (
                        <>
                            <h2 className={styles['sign-in-header']}>Get Started</h2>
                            <input
                                type="email"
                                className={styles['login-input']}
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                type="text"
                                className={styles['login-input']}
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <input
                                type="password"
                                className={styles['login-input']}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <input
                                type="password"
                                className={styles['login-input']}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {/* Terms and Conditions */}
                            <div className={styles['terms-container']}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="default"
                                            checked={agreedToTerms}
                                            onChange={(e) => setAgreedToTerms(e.target.checked)}
                                        />
                                    }
                                    label={
                                        <span className={styles['terms-text']}>
                                            I agree to the{' '}
                                            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                                Terms and Conditions
                                            </a>{' '}
                                            and{' '}
                                            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                                Privacy Policy
                                            </a>
                                        </span>
                                    }
                                />
                            </div>
                            <button className={styles['submission-button']} onClick={handleSignUp} disabled={loadingSignUp}>
                                {loadingSignUp ? <CircularProgress color="inherit" size={24} /> : 'Sign Up'}
                            </button>                        
                            {Object.keys(signUpErrors).length > 0 && (
                                <div className={styles['error-messages']}>
                                    {Object.values(signUpErrors).map((error, index) => (
                                        <p key={index} className={styles['error']}>{error}</p>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default SignInModal;
