import React from 'react';
import AppRouter from './router';
import { AuthProvider } from './components/authContext';
import { GlobalStateProvider } from './components/GlobalStateContext';

const App = () => {
  return (
    <AuthProvider>
      <GlobalStateProvider>
      <AppRouter />
      </GlobalStateProvider>
    </AuthProvider>
  );
};

export default App;