import React from 'react';
import styles from './TermsConditions.module.css';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';

const TermsConditions = () => {
  return (
    <>
      <Navbar />
      <div className={styles['terms-conditions-container']}>
        <h1 className={styles['terms-conditions-title']}>Terms and Conditions</h1>

        <section className={styles['terms-section']}>
          <h2>1. Introduction</h2>
          <p>
            Welcome to ActivityBuddy ("we," "us," or "our"), a platform connecting Users with ActivityBuddies for social and recreational companionship. These Terms and Conditions of Use (the "Terms") govern your access to and use of the ActivityBuddy platform, including the website, mobile applications, and any related services, tools, or features provided by ActivityBuddy (collectively, the "Platform"). By accessing or using the Platform, you ("User" or "you") agree to be bound by these Terms. These Terms constitute a legally binding agreement between you and ActivityBuddy. If you do not agree to these Terms, you are not authorized to access or use the Platform.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>2. Definitions</h2>
          <ul>
            <li>
              <strong>ActivityBuddy:</strong> Refers to individuals who offer companionship and participate in Activities with Users via the Platform. ActivityBuddies are independent contractors and are NOT employees, agents, partners, or representatives of ActivityBuddy.
            </li>
            <li>
              <strong>User:</strong> Any individual who accesses or uses the Platform, whether seeking or offering companionship for Activities. Users can be both "Guests" (those booking ActivityBuddies) and "ActivityBuddies" (those offering their time).
            </li>
            <li>
              <strong>Platform:</strong> The ActivityBuddy website, mobile applications, and any related services, tools, or features provided by ActivityBuddy.
            </li>
            <li>
              <strong>Activities:</strong> Social or recreational, LEGAL, and PLATONIC activities that Users and ActivityBuddies may engage in. Activities MUST be lawful and appropriate for all participants.
            </li>
            <li>
              <strong>Content:</strong> Any text, graphics, images, music, software, audio, video, information, or other materials available through the Platform.
            </li>
          </ul>
        </section>

        <section className={styles['terms-section']}>
          <h2>3. Acceptance of Terms</h2>
          <p>
            By using the Platform, you represent that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. We reserve the right to modify these Terms at any time without prior notice. Changes will be effective immediately upon posting to the Platform. Your continued use of the Platform after any changes constitutes acceptance of the modified Terms. It is your responsibility to review the Terms regularly.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>4. User Eligibility and Account Registration</h2>
          <p>
            <strong>4.1</strong> You must be at least 18 years old and have the legal capacity to enter into a binding contract to use the Platform. By creating an account, you represent and warrant that you meet these requirements.
          </p>
          <p>
            <strong>4.2</strong> You agree to provide accurate, current, and complete information during registration and to update such information to keep it accurate, current, and complete. You represent and warrant that the information you provide does not violate any third-party rights.
          </p>
          <p>
            <strong>4.3</strong> You are solely responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately of any unauthorized use of your account or any other breach of security. ActivityBuddy will not be liable for any loss or damage arising from your failure to comply with this provision.
          </p>
          <p>
            <strong>4.4</strong> We reserve the right to refuse service, terminate accounts, or cancel bookings at our sole discretion, with or without cause.
          </p>
          <p>
            <strong>4.5</strong> You may only create one account. Creating multiple accounts may result in termination of all accounts.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>5. Scope of Services</h2>
          <p>
            <strong>5.1</strong> You may only create one account. Creating multiple accounts may result in termination of all accounts.
          </p>
          <p>
            <strong>5.2</strong> We make no representations or warranties regarding the quality, safety, suitability, legality, or outcome of any Activity or the conduct of any User or ActivityBuddy.
          </p>
          <p>
            <strong>5.3</strong> Users acknowledge that participating in any Activity is SOLELY at their own risk and discretion. ActivityBuddy explicitly disclaims any liability for any harm, injury, damage, or loss incurred as a result of participating in any Activity.
          </p>
          <p>
            <strong>5.4</strong> ActivityBuddy does not provide any training, supervision, or control over ActivityBuddies. ActivityBuddies are solely responsible for their own actions and conduct.
          </p>
          <p>
            <strong>5.5</strong> ActivityBuddy is not a dating or personal service. The purpose of the service is to provide a platform for platonic, non-sexual, non-romantic, non-physical companionship between members. The service may not be used to solicit or create sexual or physical contact between members, or in any way that violates applicable law and/or these Terms of Use. You acknowledge and agree that the use of the service in violation of this provision may result in a variety of penalties, including without limitation revocation of your access to the service. If a Member contacts or solicits you in a manner that violates this provision, these Terms of Use, applicable law, and/or which makes you feel uncomfortable in any manner, please contact us immediately.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>6. User Responsibilities and Conduct</h2>
          <p>
            <strong>6.1 Assumption of Risk:</strong> You EXPRESSLY assume all risks associated with using the Platform and engaging in Activities, including but not limited to risks of physical harm, property damage, emotional distress, financial loss, or any other type of harm.
          </p>
          <p>
            <strong>6.2 Background Checks:</strong> ActivityBuddy DOES NOT conduct background checks, criminal history checks, or any other type of screening on Users or ActivityBuddies. It is YOUR sole responsibility to verify the identity and suitability of any ActivityBuddy you interact with through the Platform. You should exercise extreme caution and due diligence when selecting an ActivityBuddy.
          </p>
          <p>
            <strong>6.3 Prohibited Activities:</strong> You agree NOT to use the Platform for any unlawful, harmful, fraudulent, abusive, or unethical purposes. Prohibited activities include, but are not limited to:
            <ul>
              <li>
                Illegal activities such as solicitation, harassment, stalking, prostitution, human trafficking, or exploitation of any kind.
              </li>
              <li>
                Attempting to circumvent Platform policies or security measures.
              </li>
              <li>
                Sharing personal contact information outside the Platform prior to a confirmed booking.
              </li>
              <li>
                Engaging in any sexual or romantic activities, or making any sexually suggestive or explicit comments or advances.
              </li>
              <li>
                Using the Platform for commercial purposes without our express written consent.
              </li>
              <li>
                Discrimination or harassment based on race, ethnicity, religion, gender, sexual orientation, age, disability, or any other protected characteristic.
              </li>
              <li>
                Impersonating any person or entity, or falsely stating or misrepresenting your affiliation with any person or entity.
              </li>
              <li>
                Posting or transmitting any content that is defamatory, libelous, obscene, pornographic, offensive, hateful, or inflammatory.
              </li>
              <li>
                Violating any third-party rights, including intellectual property rights or privacy rights.
              </li>
              <li>
                Spreading misinformation, propaganda, or malicious content.
              </li>
              <li>
                Engaging in any activity that could damage, disable, overburden, or impair the Platform or interfere with any other User's access to or use of the Platform.
              </li>
              <li>
                Violation of any applicable local, state, national, or international law or regulation.
              </li>
            </ul>
          </p>
          <p>
            <strong>6.4 Compliance with Local Laws:</strong> You agree to comply with all applicable laws and regulations while using the Platform or during Activities. You are solely responsible for ensuring that your Activities are legal and compliant with all applicable laws.
          </p>
          <p>
            <strong>6.5 Respectful Conduct:</strong> You agree to maintain a respectful, non-discriminatory, and non-harassing demeanor when interacting with ActivityBuddies or other Users. You will not engage in any behavior that is abusive, threatening, intimidating, or offensive.
          </p>
          <p>
            <strong>6.6 Accuracy of Information:</strong>  You are responsible for providing truthful, accurate, and complete information in your profile and during interactions with ActivityBuddies or other Users. You will promptly update your information if it changes.
          </p>
          <p>
            <strong>6.7 Reporting Inappropriate Behaviors:</strong> You agree to immediately report any inappropriate behavior, policy violations, or safety concerns to ActivityBuddy.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>7. ActivityBuddy Responsibilities and Conduct</h2>
          <p>
            <strong>7.1 Independent Contractors:</strong> ActivityBuddies are independent contractors and are NOT employees, agents, or representatives of ActivityBuddy. ActivityBuddy does not control or direct their activities. We assume no liability for their actions, omissions, or the outcome of any Activities.
          </p>
          <p>
            <strong>7.2 Non-Guarantee:</strong> ActivityBuddy makes no guarantees regarding the outcome, enjoyment, or safety of any Activity.
          </p>
          <p>
            <strong>7.3 Compliance with Local Laws:</strong>  ActivityBuddies are responsible for complying with all applicable laws and regulations related to the Activities they engage in with Users, including but not limited to licensing requirements, permits, and insurance.
          </p>
          <p>
            <strong>7.4 Truthful Representations:</strong> ActivityBuddies agree to provide truthful and accurate information in their profiles, including their qualifications, experience, and availability.
          </p>
          <p>
            <strong>7.5 No Legal or Medical Advice:</strong> ActivityBuddy is not a licensed provider of legal, medical, financial, or any other type of professional services. Any advice or information provided by an ActivityBuddy is for entertainment purposes only and should not be considered professional advice. Users should consult with qualified professionals for any specific advice or guidance.
          </p>
          <p>
            <strong>7.6 Scope:</strong> All 'User Responsibilities and Conduct' also apply to ActivityBuddies.
          </p>
          <p>
            <strong>7.7 Insurance:</strong> ActivityBuddies are solely responsible for obtaining and maintaining adequate insurance coverage to protect themselves against any potential liabilities arising from their Activities. ActivityBuddy does not provide any insurance coverage for ActivityBuddies.
          </p>
          <p>
            <strong>7.8 Taxes:</strong> ActivityBuddies are solely responsible for reporting and paying all applicable taxes on income earned through the Platform.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>8. Payment Terms</h2>
          <p>
            <strong>8.1</strong> You agree to pay all fees associated with your use of the Platform, including booking fees, ActivityBuddy fees, and any applicable taxes. Fees are non-refundable except as explicitly stated in our Refund Policy.
          </p>
          <p>
            <strong>8.2</strong> ActivityBuddy's fees are for the use of the Platform only and do not include any payment for the Activity itself.
          </p>
          <p>
            <strong>8.3</strong> You authorize us to charge your chosen payment method for all applicable fees. You represent and warrant that you have the legal right to use the payment method you provide.
          </p>
          <p>
            <strong>8.4</strong> We reserve the right to modify our fees at any time without notice to you.
          </p>
          <p>
            <strong>8.5</strong> ActivityBuddy uses third-party payment processors. Your use of these payment processors is subject to their terms and conditions and privacy policies. ActivityBuddy is not responsible for any issues or disputes arising from your use of third-party payment processors.
          </p>
          <p>
            <strong>8.6</strong>  In the event of a dispute over payment, you agree to contact ActivityBuddy customer support to attempt to resolve the issue. If the dispute cannot be resolved, it will be subject to the Dispute Resolution provisions of these Terms.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>9. Communication</h2>
          <p>
            <strong>9.1</strong> Users and ActivityBuddies agree to communicate through the Platform's messaging system.
          </p>
          <p>
            <strong>9.2</strong> Sharing personal contact information (e.g., phone numbers, email addresses, social media handles) is prohibited to protect your privacy and security. ActivityBuddy is not responsible for any issues arising from communication outside of the Platform.
          </p>
          <p>
            <strong>9.3</strong> ActivityBuddy may monitor communications on the Platform to ensure compliance with these Terms and to protect the safety and security of our Users.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>10. Intellectual Property</h2>
          <p>
            <strong>10.1</strong> All content on the Platform, including but not limited to text, graphics, logos, images, and software, is the property of ActivityBuddy or its licensors and is protected by copyright and other intellectual property laws.
          </p>
          <p>
            <strong>10.2</strong> You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Platform, except as follows:
            <ul>
              <li>
                Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
              </li>
              <li>
                You may store files that are automatically cached by your Web browser for display enhancement purposes.
              </li>
              <li>
                You may print or download one copy of a reasonable number of pages of the Platform for your own personal, non-commercial use and not for further reproduction, publication, or distribution.
              </li>
            </ul>
          </p>
          <p>
            <strong>10.3</strong> If you violate these intellectual property rights, your right to use the Platform will stop immediately and you must, at our option, return or destroy any copies of the materials you have made.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>11. User Content</h2>
          <p>
            <strong>11.1</strong> Users may be able to post, upload, or otherwise submit content to the Platform, including profile and listing information, reviews, and messages (collectively, "User Content").
          </p>
          <p>
            <strong>11.2</strong> You retain ownership of your User Content. However, by posting User Content to the Platform, you grant ActivityBuddy a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content in connection with the Platform and our business, including for promotional purposes.
          </p>
          <p>
            <strong>11.3</strong> You are solely responsible for your User Content and the consequences of posting or publishing it. You represent and warrant that your User Content does not violate these Terms or any third-party rights.
          </p>
          <p>
            <strong>11.4</strong> ActivityBuddy reserves the right to remove or modify User Content for any reason, without notice and at our sole discretion.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>12. Limitation of Liability</h2>
          <p>
            <strong>12.1</strong> TO THE FULLEST EXTENT PERMITTED BY LAW, ACTIVITYBUDDY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND AFFILIATES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, ARISING OUT OF YOUR USE OF THE PLATFORM OR ENGAGEMENT IN ANY ACTIVITIES, EVEN IF ACTIVITYBUDDY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            <strong>12.2</strong> ACTIVITYBUDDY'S TOTAL LIABILITY TO YOU FOR ANY CLAIM ARISING OUT OF OR RELATING TO THESE TERMS OR THE PLATFORM SHALL NOT EXCEED THE AMOUNT YOU HAVE PAID TO ACTIVITYBUDDY IN THE TWELVE (12) MONTHS PRECEDING THE CLAIM.
          </p>
          <p>
            <strong>12.3</strong> THE PLATFORM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. ACTIVITYBUDDY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
          </p>
          <p>
            <strong>12.4</strong> ACTIVITYBUDDY DOES NOT WARRANT THAT THE PLATFORM WILL BE UNINTERRUPTED, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          </p>
          <p>
            <strong>12.5</strong> YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE PLATFORM AND ENGAGEMENT IN ANY ACTIVITIES IS AT YOUR SOLE RISK.
          </p>
          <p>
            <strong>12.6</strong> THIS LIMITATION OF LIABILITY IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN YOU AND ACTIVITYBUDDY.
          </p>
          <p>
            <strong>12.7</strong> SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, ACTIVITYBUDDY'S LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>13. Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless ActivityBuddy, its officers, directors, employees, agents, and affiliates from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Platform, your violation of these Terms, your User Content, or your engagement in any Activities. This includes, but is not limited to, any claims arising from your interactions with other Users, your violation of any laws or regulations, or your infringement of any third-party rights.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>14. Dispute Resolution</h2>
          <p>
            <strong>14.1 Binding Arbitration:</strong> Any disputes arising out of or relating to these Terms or the Platform, including disputes relating to the interpretation, validity, enforceability, or breach of these Terms, shall be resolved through binding arbitration administered by the American Arbitration Association (AAA) in accordance with its Commercial Arbitration Rules. The arbitration shall be conducted in the state of Florida, unless otherwise agreed by the parties.
          </p>
          <p>
            <strong>14.2 Individual Basis:</strong> You agree that any arbitration will be conducted on an individual basis only, and not on a class, consolidated, or representative basis. You expressly waive any right to participate in a class action or class arbitration.
          </p>
          <p>
            <strong>14.3 Arbitrator's Authority:</strong> The arbitrator shall have the authority to award any relief that would be available in a court of law, including injunctive or declaratory relief. The arbitrator's decision shall be final and binding on the parties and may be entered as a judgment in any court of competent jurisdiction.
          </p>
          <p>
            <strong>14.4 Exception:</strong> Notwithstanding the foregoing, ActivityBuddy may seek injunctive or other equitable relief in any court of competent jurisdiction to protect its intellectual property rights or to prevent imminent harm to its business or reputation.
          </p>
          <p>
            <strong>14.5 Costs:</strong> Each party shall bear its own costs of arbitration, including attorneys' fees, except that the arbitrator may award reasonable attorneys' fees to the prevailing party if authorized by applicable law.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>15. Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles.
          </p>
        </section>


        <section className={styles['terms-section']}>
          <h2>16. Termination</h2>
          <p>
            <strong>16.1</strong> ActivityBuddy reserves the right to suspend or terminate your access to the Platform for any reason, with or without notice and at our sole discretion. Reasons for termination may include, but are not limited to, violation of these Terms, suspected fraudulent activity, or conduct that is harmful to the Platform or other Users.
          </p>
          <p>
            <strong>16.2</strong> Upon termination, all rights granted to you under these Terms will immediately cease. You will no longer be authorized to access or use the Platform.
          </p>
          <p>
            <strong>16.3</strong> ActivityBuddy shall not be liable to you or any third party for any termination of your access to the Platform.
          </p>
          <p>
            <strong>16.4</strong> Sections 11 (Intellectual Property), 12 (Limitation of Liability), 13 (Indemnification), 14 (Dispute Resolution), and 15 (Governing Law) shall survive any termination of these Terms.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>17. Severability</h2>
          <p>
            If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and enforceable. The invalidity or unenforceability of one provision shall not affect the validity or enforceability of any other provision of these Terms.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>18. Entire Agreement</h2>
          <p>
            These Terms constitute the entire agreement between you and ActivityBuddy regarding the Platform and supersede all prior agreements and understandings, whether written or oral.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>19. Force Majeure</h2>
          <p>
            ActivityBuddy shall not be liable for any delay or failure to perform its obligations under these Terms due to causes beyond its reasonable control, including but not limited to acts of war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor, or materials.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>20. Assignment</h2>
          <p>
            You may not assign or transfer these Terms, in whole or in part, without ActivityBuddy's prior written consent. ActivityBuddy may assign or transfer these Terms, in whole or in part, without your consent.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>21. No Waiver</h2>
          <p>
            ActivityBuddy's failure to enforce any right or provision of these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by ActivityBuddy in writing.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>22. Third-Party Links</h2>
          <p>
            The Platform may contain links to third-party websites or resources. ActivityBuddy is not responsible for the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any third-party websites or resources.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>23. Contact Information</h2>
          <p>
            For any questions or concerns regarding these Terms, please contact us at support@activitybuddy.com.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <h2>24. Electronic Communications</h2>
          <p>
            By using the Platform, you consent to receiving electronic communications from ActivityBuddy, including notices, agreements, disclosures, and other communications. You agree that all such electronic communications satisfy any legal requirement that such communications be in writing.
          </p>
        </section>

        <section className={styles['terms-section']}>
          <p>
            BY USING THE ACTIVITYBUDDY PLATFORM, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
          </p>
        </section>


      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;