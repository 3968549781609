import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './authContext';

const PrivateRoute = ({ element }) => {
    const { user, loading } = useAuth();
    const location = useLocation();  // Capture the current location dynamically

    if (loading) {
        return <div>Loading...</div>
    }
    if (!user) {
        localStorage.setItem('redirectPath', location.pathname);
        return <Navigate to="/login"  state={{ from: location }} replace />;
    }

    return element;
};

export default PrivateRoute;
