import React from "react";
import "./Footer.css";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa6";


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-socials">
        <a href="https://www.tiktok.com/@activitybuddy" target="_blank" rel="noopener noreferrer">
          <FaTiktok />
        </a>
        <a href="https://www.instagram.com/activitybuddy/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61571192333730" target="_blank" rel="noopener noreferrer">
          <FaFacebookF />
        </a>
      </div>
      <div className="footer-links">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-and-conditions">Terms and Conditions</a>
        <a href="/contact-us">Contact Us</a>
      </div>
      <div className="footer-copyright">
        &copy; 2025 ActivityBuddy. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
