import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Navbar from '../components/NavBar';
import { getAuth } from 'firebase/auth';

import './Listing.css';
import { GridView, ArrowBackIos, ArrowForwardIos, Close } from '@mui/icons-material';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const CLIENT_BASE_URL = process.env.REACT_APP_CLIENT_URL;



const Listing = () => {

  const { id } = useParams(); // Get listing ID from URL
  const [listingDetails, setListingDetails] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // For modal
  const [showAllPhotos, setShowAllPhotos] = useState(false); // Toggle for modal
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const updateMobileStatus = () => setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
      updateMobileStatus();
      window.addEventListener('resize', updateMobileStatus);
      return () => window.removeEventListener('resize', updateMobileStatus);
    }, []);

    return isMobile;
  };

  const isMobile = useIsMobile();
  useEffect(() => {
    // Fetch details for the selected listing
    const fetchListingDetails = async () => {
      const auth = getAuth();
      let token = null;
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            user.getIdToken().then((fetchedToken) => {
              token = fetchedToken;
              resolve();
            }).catch((error) => {
              console.error('Error fetching token:', error);
              resolve(); // Proceed even if token retrieval fails
            });
          } else {
            resolve(); // No user is logged in
          }
        });
        return () => unsubscribe();
      });
      try {
        const response = await fetch(`${API_BASE_URL}/api/user/listing/${id}`, {
          method: 'GET',
          headers: {
            // Send token in the Authorization header if it exists
            ...(token && { 'Authorization': `Bearer ${token}` })
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch listing details');
        }
        const data = await response.json();
        setListingDetails(data.listing);
        setIsOwner(data.isOwner);
      } catch (error) {
        console.error('Error fetching listing details:', error);
      }
    };

    fetchListingDetails();
  }, [id]);

  const handleSetupStripe = async () => {
    setLoadingStripe(true)
    try {
      const auth = await getAuth()
      const user = auth.currentUser;
      let token = null

      if (user) {
        token = await user.getIdToken()
      }
      const response = await fetch(`${API_BASE_URL}/api/user/setup/stripe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Send token in the Authorization header if it exists
          ...(token && { 'Authorization': `Bearer ${token}` })
        }
      })

      const responseData = await response.json();
      if (responseData.accountLinkUrl) {
        window.location.href = responseData.accountLinkUrl;
      } else {
        throw new Error('Stripe link failed to generate.')
      }
    } catch (error) {
      throw new Error('Error generating Stripe link:', error);
    } finally {
      setLoadingStripe(false);
    }
  }

  const categoryMapping = [
    {
      title: "Outdoors",
      filterKey: "outdoors",
    },
    {
      title: "Food & Drink",
      filterKey: "food",
    },
    {
      title: "Fitness & Sports",
      filterKey: "fitness"
    },
    {
      title: "Arts & Culture",
      filterKey: "arts",
    },
    {
      title: "Events",
      filterKey: "events",
    },
    {
      title: "Hobbies",
      filterKey: "hobbies",
    },
    {
      title: "Nightlife",
      filterKey: "night",
    },
    {
      title: "Online",
      filterKey: "online",
    },
    {
      title: "Safety",
      filterKey: "night",
    }
  ];


  const activeCategories = listingDetails?.categories
    ? categoryMapping.filter(
      (category) => listingDetails.categories[category.filterKey]
    )
    : [];

  if (!listingDetails) {
    return <p>Loading...</p>;
  }

  const openModal = () => {
    setSelectedImage(listingDetails.images[0]); // Set to the first image
    setShowAllPhotos(true);
  };
  const closeModal = () => setShowAllPhotos(false);


  const navigateModal = (direction) => {
    const imageCount = listingDetails.images.length;
    const currentIndex = listingDetails.images.indexOf(selectedImage);
    const newIndex =
      direction === 'next'
        ? (currentIndex + 1) % imageCount
        : (currentIndex - 1 + imageCount) % imageCount;
    setSelectedImage(listingDetails.images[newIndex]);
  };

  return (
    <>
      <Navbar />
      <div className="listing-page">
        <div className="content-wrapper">
          {/* Image Gallery */}
          <div className="image-gallery">
            {isMobile ? (
              // Mobile Gallery (Horizontal Scroll)
              <div className="mobile-gallery"
                onScroll={(e) => {
                  const scrollPosition = e.target.scrollLeft;
                  const width = e.target.offsetWidth;
                  const currentIndex = Math.round(scrollPosition / width);
                  setSelectedImageIndex(currentIndex);
                }}
              >
                {listingDetails.images.map((image, index) => (
                  <div className="mobile-image-container" key={index}>
                    <img
                      src={image}
                      alt={`Gallery ${index + 1}`}
                      className="mobile-gallery-image"
                    />
                  </div>
                ))}
              </div>
            ) : (
              listingDetails.images.slice(0, 3).map((image, index) => (
                <div className="image-container" key={index}>
                  <img
                    key={index}
                    src={image}
                    alt={`Gallery ${index + 1}`}
                    className="gallery-image"
                  />
                  {index === 2 && (  // Only render the button for the 3rd image
                    <div className="show-all-button-container">
                      <button className="show-all-button" onClick={openModal}>
                        <GridView className="show-all-icon" /> Show all photos
                      </button>
                    </div>
                  )}
                </div>
              ))
            )}
            {isMobile && listingDetails.images.length > 0 && (
              <div className="image-indicator">
                {selectedImageIndex + 1}/{listingDetails.images.length}
              </div>
            )}
          </div>
          {/* Modal for Fullscreen Image */}
          {showAllPhotos && (
            <div className="image-modal">
              <ArrowBackIos className="gallery-arrow left" onClick={() => navigateModal('prev')}>&lt;</ArrowBackIos>

              <div className="modal-image-container">
                <img src={selectedImage} alt="Selected" className="modal-image" />
                <div className="close-modal-container" onClick={closeModal}>
                  <Close className="close-modal" />
                </div>
              </div>

              <ArrowForwardIos className="gallery-arrow right" onClick={() => navigateModal('next')}>&gt;</ArrowForwardIos>
            </div>
          )}
          {isOwner ? (
            <>
              {/* Case 2: Owner and bank_info is true AND approved is true */}
              {listingDetails.bank_info && listingDetails.approved && (
                <div></div>
              )}

              {/* Case 3: Owner and bank_info is false AND approved is false */}
              {!listingDetails.bank_info && !listingDetails.approved && (
                <>
                  <div className="enable-payments-warning">
                    To have your listing published, you need to setup Stripe to collect payments.
                  </div>
                  <button onClick={handleSetupStripe} disabled={loadingStripe} className="setup-stripe-button">
                    Setup Stripe Account
                  </button>
                </>
              )}

              {/* Case 4: Owner and bank_info is true AND approved is false */}
              {listingDetails.bank_info && !listingDetails.approved && (
                <>
                  <div className="enable-listing-warning">
                    Your listing is under review. This message will disappear once your listing is published. If you don’t hear from us within a few days, please {' '}
                    <a href={`${CLIENT_BASE_URL}/contact-us`} target="_self" rel="noopener noreferrer">contact us</a>.
                  </div>
                </>
              )}
            </>
          ) : (
            // Case 1: Not the owner
            <div></div>
          )}
          {/* Profile Section */}
          <div className="profile-container">
            <div className="profile-section">
              <img
                className="profile-photo"
                src={listingDetails.profile_photo}
                alt={listingDetails.first_name}
              />
              <div className="profile-info">
                <h2>
                  {listingDetails.first_name.charAt(0).toUpperCase() + listingDetails.first_name.slice(1).toLowerCase()}
                  {" "}
                  {(() => {
                    const currentDate = new Date();
                    const birthDate = new Date(listingDetails.date_of_birth);
                    let age = currentDate.getFullYear() - birthDate.getFullYear();

                    // Check if the birthday has passed this year
                    const currentMonth = currentDate.getMonth();
                    const currentDay = currentDate.getDate();
                    const birthMonth = birthDate.getMonth();
                    const birthDay = birthDate.getDate();

                    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
                      age--;  // Subtract 1 if birthday hasn't passed yet
                    }

                    return age;
                  })()}
                </h2>                  <p>{listingDetails.gender}</p>
                <p>{listingDetails.city}</p>
              </div>
            </div>

            {/* Tags */}
            <div className="tags-container">
              {activeCategories.map((category, index) => (
                <span key={index} className="tag">{category.title}</span>
              ))}
            </div>

            {/* Bio */}
            <p className="bio">{listingDetails.bio}</p>
          </div>
        </div>
        {/* Sticky Booking Section */}
        <div className="sticky-booking">
          {/* Price Section */}
          <div className="booking-info">
            <span className="hourly-price">${listingDetails.hourly_price}/hr</span>
          </div>


          {/* Book Now Section */}
          <div className="continue-container">
            {isOwner ? (
              <Link to={`/editlisting/${id}`} className="continue">Edit</Link>
            ) : (
              <Link to={`/booking/${id}`} state={{ listing: listingDetails }} className="continue">Continue</Link>
            )}          </div>
        </div>

      </div>
    </>
  );
};

export default Listing;
