import "./Profile.css";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { TextField, MenuItem, Button, IconButton } from "@mui/material";
import { AccountCircle } from '@mui/icons-material';
import EditIcon from "@mui/icons-material/Edit";

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const Profile = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isOwnProfile, setIsOwnProfile] = useState(false);
    const [editMode, setEditMode] = useState({
        first_name: false,
        city: false,
        profile_photo: false,
    });
    const [errors, setErrors] = useState({
        first_name: "",
        profile_photo: "",
    });
    const fileInputRef = useRef(null);

    const [saveButtonVisible, setSaveButtonVisible] = useState(false);

    const [updatedProfile, setUpdatedProfile] = useState({
        first_name: "",
        city: "",
        profile_photo: "",
        profile_photo_file: null,
    });

    useEffect(() => {
        const fetchProfile = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                navigate("/login");
                return;
            }

            const token = await user.getIdToken();
            try {
                const response = await fetch(`${API_BASE_URL}/api/user/profile/${id}`, {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.ok) {
                    const data = await response.json();
                    setProfile(data);
                    setIsOwnProfile(data.isOwnProfile);
                    setUpdatedProfile({
                        first_name: data.first_name || "",
                        city: data.city || "",
                        profile_photo: data.activitybuddy_info?.profile_photo || "",
                    });
                    setSaveButtonVisible(false);

                    // Initialize updatedProfile with fetched data
                    setUpdatedProfile({
                        first_name: data.first_name || "",
                        city: data.city || "",
                        profile_photo: data.activitybuddy_info?.profile_photo || "",
                    });
                } else {
                    console.error("Failed to fetch profile.");
                }
            } catch (error) {
                console.error("Error fetching profile:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProfile();
    }, [id, navigate]);

    const handleEditClick = (field, event) => {
        if (field === "profile_photo") {
            handlePhotoUpload(event);
        }
        // Toggle edit mode for other fields
        setEditMode({ ...editMode, [field]: true });
    };

    const handleIconClick = () => {
        if (fileInputRef.current) {
            // Trigger the file input only if ref is not null
            fileInputRef.current.click();
        } else {
            console.error("File input reference is null.");
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "first_name") {
            if (value.trim() === "") {
                setErrors((prev) => ({ ...prev, first_name: "First name cannot be empty." }));
            } else if (value.length > 30) {
                return; // Prevent further input
            } else {
                setErrors((prev) => ({ ...prev, first_name: "" }));
            }
        }

        setUpdatedProfile((prev) => {
            const newProfile = { ...prev, [name]: value };
            // Check if any of the fields have been changed
            const isChanged = Object.keys(newProfile).some(key => newProfile[key] !== profile[key]);
            setSaveButtonVisible(isChanged);
            return newProfile;
        });
    };

    const handlePhotoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validFormats = ["image/jpeg", "image/jpg", "image/png"];
            if (!validFormats.includes(file.type)) {
                setErrors((prev) => ({ ...prev, profile_photo: "Invalid file format. Use JPG, JPEG, or PNG." }));
                return;
            } else {
                setErrors((prev) => ({ ...prev, profile_photo: "" }));
            }
            const tempPreviewUrl = URL.createObjectURL(file);
            setUpdatedProfile((prev) => {
                const newProfile = { 
                    ...prev, 
                    profile_photo: tempPreviewUrl,  // Use preview URL for UI display
                    profile_photo_file: file        // Store the actual file for uploading
                };
    
                // Check if any of the fields have changed
                const isChanged = Object.keys(newProfile).some(key => newProfile[key] !== profile[key]);
                setSaveButtonVisible(isChanged);  
    
                return newProfile;
            });
        }
    };

    const handleSave = async () => {
        if (!updatedProfile.first_name.trim()) {
            setErrors((prev) => ({ ...prev, first_name: "First name cannot be empty." }));
            return;
        }
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) return;

        const token = await user.getIdToken();

        const formData = new FormData();

        // Append the profile fields
        formData.append("first_name", updatedProfile.first_name);
        formData.append("city", updatedProfile.city);
    
        // Check if a new photo file exists
        if (updatedProfile.profile_photo_file) {
            formData.append("profile_photo", updatedProfile.profile_photo_file);
        }

        try {
            const response = await fetch(`${API_BASE_URL}/api/user/profile/${id}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setProfile((prev) => ({
                    ...prev,
                    ...updatedProfile,
                    profile_photo: data.profile_photo_url, // Assuming the server responds with the new photo URL
                }));
                setEditMode({ first_name: false, city: false, profile_photo: false });
                setSaveButtonVisible(false);
            } else {
                console.error("Failed to update profile.");
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (!profile) return <div>Profile not found</div>;

    return (
        <>
            <Navbar />
            <div className="profile-profile-container">
                <div className="profile-profile-column">
                    {/* Profile Photo and Fields Container with Border */}
                    <div className="profile-profile-fields-container">

                        <div className="profile-profile-info-container">
                            <div className="profile-profile-field">
                                {/* Profile Photo */}
                                <div className="profile-profile-photo-section">
                                    {updatedProfile.profile_photo ? (
                                        <img
                                            src={updatedProfile.profile_photo}
                                            alt="Profile"
                                            className="profile-profile-photo"
                                        />
                                    ) : (
                                        <AccountCircle className="profile-profile-photo-placeholder" />
                                    )}
                                    {isOwnProfile && (
                                        <>
                                            <IconButton size="small" onClick={handleIconClick}>
                                                <EditIcon className="edit-icon-photo" />
                                            </IconButton>

                                            <input
                                                type="file"
                                                accept="image/jpeg, image/jpg, image/png"
                                                onChange={(e) => handleEditClick("profile_photo", e)}
                                                className="file-input"
                                                style={{ display: 'none' }}
                                                ref={fileInputRef}
                                            />

                                        </>
                                    )}
                                </div>
                            </div>

                            {/* Fields Container */}
                            {/* First Name */}
                            <div className="profile-profile-field-container">
                                <div className="profile-profile-field">
                                    {editMode.first_name ? (
                                        <TextField
                                            name="first_name"
                                            value={updatedProfile.first_name}
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="small"
                                            onBlur={() => setEditMode({ ...editMode, first_name: false })}
                                            autoFocus
                                        />
                                    ) : (
                                        <h2>{updatedProfile.first_name}</h2>
                                    )}
                                    {isOwnProfile && (
                                        <IconButton onClick={() => handleEditClick("first_name")} size="small" className="edit-icon">
                                            <EditIcon className="edit-icon" />
                                        </IconButton>
                                    )}
                                </div>

                                {/* City */}
                                <div className="profile-profile-field">
                                    {editMode.city ? (
                                        <TextField
                                            select
                                            name="city"
                                            value={updatedProfile.city}
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="small"
                                            autoFocus
                                            onBlur={() => setEditMode({ ...editMode, city: false })}
                                        >
                                            <MenuItem value="Tampa, FL">Tampa, FL</MenuItem>
                                            <MenuItem value="Miami, FL">Miami, FL</MenuItem>
                                        </TextField>
                                    ) : (
                                        <p>{updatedProfile.city || "City"}</p>
                                    )}
                                    {isOwnProfile && (
                                        <IconButton onClick={() => handleEditClick("city")} size="small" className="edit-icon">
                                            <EditIcon className="edit-icon" />
                                        </IconButton>
                                    )}
                                </div>
                                {/* Save Button (Only if changes were made) */}
                                {isOwnProfile && saveButtonVisible && (
                                    <div>
                                        <button className="save-edit-profile-button" onClick={handleSave}>
                                            Save Changes
                                        </button>
                                        {Object.values(errors).some(error => error) && (
                                            <div className="error-messages">
                                                {errors.first_name && <p className="error-message">{errors.first_name}</p>}
                                                {errors.profile_photo && <p className="error-message">{errors.profile_photo}</p>}
                                            </div>
                                        )}                                    </div>
                                )}
                                {/* View Listing Button */}
                                {profile.is_activitybuddy && (
                                    <Link to={`/listing/${profile.activitybuddy_info.listing_id}`} className="view-listing-btn">
                                        View My Listing
                                    </Link>
                                )}
                                {!profile.is_activitybuddy && isOwnProfile && (
                                    <Link to={"/become-activitybuddy"} className="view-listing-btn">
                                        Become an ActivityBuddy
                                    </Link>
                                )}
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            {/* Center Section: Reviews */}
            <div className="reviews-section">
                <h2>Reviews</h2>
                <div className="review-box">
                    <div className="coming-soon-overlay">Coming soon!</div>
                    <div className="reviews-tabs">
                        <button>Host</button>
                        <button>Guest</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Profile;
