import React from 'react';
import './HowItWorks.css'; 

const HowItWorks = ({ isOpen, onClose }) => {
    const steps = [
        {
            title: "Step 1: Choose an Activity",
            description: "Decide what you want to do — whether it’s dining, hobbies, workouts, or just hanging out. Any legal, safe, and platonic activity works. For safety, we recommend public places.",
            imageUrl: "https://activitybuddyimages.s3.us-east-2.amazonaws.com/static_images/howitworks1.png"
        },
        {
            title: "Step 2: Find Your ActivityBuddy",
            description: "Browse listings of available ActivityBuddies based on your preferences. Use filters like location, activity categories, and age to find the perfect match. For safety, every ActivityBuddy is verified through Stripe, so no fake names or shady stuff.",
            imageUrl: "https://activitybuddyimages.s3.us-east-2.amazonaws.com/static_images/howitworks2.png"
        },
        {
            title: "Step 3: Send a Booking Request",
            description: "Once you’ve found the right ActivityBuddy, fill out the booking form with activity details — including what, where, when, and how long. A payment hold will be placed upon submission.",
            imageUrl: "https://activitybuddyimages.s3.us-east-2.amazonaws.com/static_images/howitworks3.png"
        },
        {
            title: "Step 4: Discuss Details Through Messages",
            description: "After submitting a booking request, you'll be connected with your ActivityBuddy through our secure in-app messaging. Use this to finalize activity details and expectations. For safety and support, all communication should stay within the platform.",
            imageUrl: "https://activitybuddyimages.s3.us-east-2.amazonaws.com/static_images/howitworks4.png"
        },
        {
            title: "Step 5: Wait For Confirmation",
            description: "Leading up to your activity, your ActivityBuddy will either accept or decline the request. Both you and the ActivityBuddy can cancel up to 24 hours before the activity starts. If an activity is declined, canceled, or expires, you’ll receive a full refund.",
            imageUrl: "https://activitybuddyimages.s3.us-east-2.amazonaws.com/static_images/howitworks5.png"
        },
        {
            title: "Step 6: Enjoy and Leave a Review",
            description: "Meet up with your ActivityBuddy and have fun! Remember, any costs related to the activity (like tickets, meals, or rentals) are your responsibility. Afterwards, leave a review to share your experience and help others in the community.",
            imageUrl: "https://activitybuddyimages.s3.us-east-2.amazonaws.com/static_images/howitworks6.png"
        }
    ];

    return isOpen ? (
        <div className="how-it-works-modal-overlay">
            <div className="how-it-works-modal">
                <button className="how-it-works-close-modal" onClick={onClose}>X</button>
                <h2 className="how-it-works-modal-main-header">How It Works</h2>
                <div className="how-it-works-steps-container">
                    {steps.map((step, index) => (
                        <div key={index} className="how-it-works-step">
                            <h3>{step.title}</h3>
                            <p className="how-it-works-modal-p">{step.description}</p>
                            <img src={step.imageUrl} alt="" className="how-it-works-step-image" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null;
};

export default HowItWorks;
