import React, { useState, useEffect } from "react";
import "./EditListing.css";
import { useParams, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { CameraAlt, AccountCircle } from '@mui/icons-material';
import CurrencyInput from 'react-currency-input-field';

import { Checkbox, FormControlLabel, FormGroup, CircularProgress, TextField, MenuItem, FormControl } from '@mui/material';
import Navbar from "../components/NavBar";


const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const EditListing = () => {
  const { id } = useParams(); // Get listing ID
  const navigate = useNavigate();
  const [disableForm, setDisableForm] = useState(false);

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [listingPhotos, setListingPhotos] = useState([]);
  const [rate, setRate] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cityPlaceHolder, setCityPlaceHolder] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [payments, setPayments] = useState(false);
  const [disableEditPayments, setDisableEditPayments] = useState(false);
  const [description, setDescription] = useState("");
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);

  const [checkBoxStates, setCheckBoxStates] = useState({
    other: false,
    outdoors: false,
    food: false,
    fitness: false,
    arts: false,
    events: false,
    hobbies: false,
    night: false,
    online: false,
    safety: false
  });

  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Fetch listing data to populate form
    const fetchListingDetails = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        navigate('/login')
        return;
      }

      const token = await user.getIdToken();
      try {
        const response = await fetch(`${API_BASE_URL}/api/user/listing/${id}/edit`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          // Handle errors based on response status
          const errorData = await response.json();
          if (response.status === 403) {
            if (!errorData.isActivityBuddy) {
              // If the error is related to ActivityBuddy status
              navigate("/become-activitybuddy");
              return;
            }

            if (!errorData.isOwner) {
              navigate(`/listing/${id}`);
              return;
            }
          }

          throw new Error("Failed to fetch listing details");
        } const data = await response.json();
        setProfilePhoto(data.listing.profile_photo)
        setProfilePhotoPreview(data.listing.profile_photo)
        setFirstName(data.listing.first_name);
        setListingPhotos(data.listing.images.map((url) => ({ preview: url })))
        setSelectedCity(data.listing.city)
        setDescription(data.listing.bio)
        setRate(data.listing.hourly_price)
        setCheckBoxStates(data.listing.categories)
        setPayments(data.listing.bank_info)
      } catch (error) {
        console.error(error);
      }
    };

    fetchListingDetails();
  }, [id, navigate]);

  const handleRateChange = (value) => {
    setRate(value); // Value will be a string (with the dollar sign and decimals)
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setCityPlaceHolder(false); // Ensure the placeholder doesn't reappear
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckBoxStates((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhoto({
          file,
          preview: reader.result,
        })
        setProfilePhotoPreview(reader.result);  // Set the preview URL
      };
      reader.readAsDataURL(file);  // Convert file to base64 for preview

    }
  };

  const handleListingPhotoChange = (e) => {
    const files = Array.from(e.target.files);

    const uniqueFiles = files.filter((newFile) => {
      return !listingPhotos.some(
        (photo) => photo.file && photo.file.name === newFile.name && photo.file.size === newFile.size
      );
    });

    const updatedPhotos = uniqueFiles.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve({
            file,  // Store the file for submission
            preview: reader.result,  // Base64 preview URL
          });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(updatedPhotos).then((photos) => {
      setListingPhotos((prevPhotos) => [...prevPhotos, ...photos]);
    });
  };

  const handleDeleteListingPhoto = (photoPreviewUrl) => {
    setListingPhotos((prevPhotos) => {
      const updatedPhotos = prevPhotos.filter((photo) => photo.preview && photo.preview !== photoPreviewUrl) // Ensure photo.file exists before accessing name
      return updatedPhotos;
    });
  };

  const setupStripe = async () => {
    try {
      const auth = await getAuth()
      const user = auth.currentUser;
      let token = null

      if (user) {
        token = await user.getIdToken()
      }
      const response = await fetch(`${API_BASE_URL}/api/user/setup/stripe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Send token in the Authorization header if it exists
          ...(token && { 'Authorization': `Bearer ${token}` })
        }
      })

      const responseData = await response.json();
      if (responseData.accountLinkUrl) {
        window.location.href = responseData.accountLinkUrl;
      } else {
        throw new Error('Stripe link failed to generate.')
      }
    } catch (error) {
      throw new Error('Error generating Stripe link:', error);
    }
  }

  const editStripe = async () => {
    setDisableEditPayments(true);
    try {
      const auth = await getAuth()
      const user = auth.currentUser;
      let token = null

      if (user) {
        token = await user.getIdToken()
      }
      const response = await fetch(`${API_BASE_URL}/api/user/edit/stripe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Send token in the Authorization header if it exists
          ...(token && { 'Authorization': `Bearer ${token}` })
        }
      })

      const responseData = await response.json();
      if (responseData.accountLinkUrl) {
        window.location.href = responseData.accountLinkUrl;
      } else {
        throw new Error('Stripe link failed to generate.')
      }
    } catch (error) {
      throw new Error('Error generating Stripe link:', error);
    }
    setDisableEditPayments(false);
  }

  const validateForm = () => {
    const errors = {};
    const numericRate = parseFloat(String(rate).replace(/[^\d.-]/g, ''));

    if (!selectedCity) errors.selectedCity = "City is required.";
    if (!rate) {
      errors.rate = "Hourly rate is required."
    }
      else if (isNaN(numericRate)) {
      errors.rate = "Invalid hourly rate.";
    } else if (numericRate < 15) {
      errors.rate = "Hourly rate must be at least $15."
    } else if (numericRate > 1000000) {
      errors.rate = "Too many characters in hourly rate field."
    }
    if (!description) errors.description = "Listing description is required.";
    else if (description.length > 500) errors.description = "Listing description must be 500 characters or less.";
    if (!Object.values(checkBoxStates).includes(true)) errors.checkBoxStates = "At least one activity must be selected.";
    if (!profilePhoto) errors.profilePhoto = "Profile photo is required.";
    if (listingPhotos.length === 0) errors.listingPhotos = "At least one listing photo is required.";
    if (listingPhotos.length > 10) errors.listingPhotos = "Only 10 maximum listing photos allowed.";


    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) return;
    const token = await user.getIdToken();


    if (!validateForm()) return;
    setIsSubmitting(true);


    try {

      const formData = new FormData();
      // Handle Profile Photo
      if (profilePhoto.file) {
        // Upload new image file
        formData.append('profilePhoto', profilePhoto.file);
      } else if (profilePhoto.preview) {
        // Fetch image if preview exists
        const response = await fetch(profilePhoto.preview);
        const blob = await response.blob();

        const urlParts = profilePhoto.preview.split(".");
        const extension = urlParts.length > 1 ? urlParts[urlParts.length - 1] : "jpg"; // Fallback to jpg if extension missing
        const file = new File([blob], `profile-photo.${extension}`, { type: blob.type });
        formData.append('profilePhoto', file);
      }

      // Handle Listing Photos
      for (const photo of listingPhotos) {
        if (photo.file) {
          // Upload new image file
          formData.append('listingPhotos', photo.file);
        } else if (photo.preview) {
          // Fetch image if preview exists
          const response = await fetch(photo.preview);
          const blob = await response.blob();

          const urlParts = photo.preview.split(".");
          const extension = urlParts.length > 1 ? urlParts[urlParts.length - 1] : "jpg"; // Fallback to jpg if extension missing
          const uniqueName = `${Date.now()}-${Math.floor(Math.random() * 1000)}.${extension}`;

          const file = new File([blob], uniqueName, { type: blob.type });
          formData.append('listingPhotos', file);
        }
      }

      const data = {
        rate,
        selectedCity,
        description,
        checkBoxStates: JSON.stringify(checkBoxStates)
      };

      // Append other fields to formData if necessary
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      const response = await fetch(`${API_BASE_URL}/api/user/listing/${id}/edit`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "Pragma": "no-cache",
          "Expires": "0",
        },
        body: formData
      });
      if (!response.ok) {
        setErrors({ email: 'An error occured, please try again later or contact us.' })
        setDisableForm(true);
        setIsSubmitting(false);
      }
      navigate(`/listing/${id}`);
    } catch (error) {
      setErrors({ email: 'An error occured, please try again later or contact us.' })
      setDisableForm(true);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="edit-listing-page">
        <h1 className="edit-listing-header">Edit My Listing</h1>
        {/* Photos Section */}
        <div className="edit-photos-section">
          <h3 className="edit-listing-subheader">Photos</h3>
          <p className="edit-subtext">Upload your profile photo</p>

          <div className="edit-profile-photo-upload">
            <div className="edit-photo-circle">
              {profilePhotoPreview ? (
                <img src={profilePhotoPreview} alt="Profile" className="edit-profile-photo-preview" />
              ) : (
                <AccountCircle className="edit-photo-icon" fontSize="large" />
              )}
            </div>
            <label className="edit-upload-button">
              <CameraAlt className="edit-camera-icon" />
              <span>Upload</span>
              <input
                type="file"
                accept="image/jpeg, image/jpg, image/png"
                onChange={handleProfilePhotoChange}
                className="file-input"
              />
            </label>
          </div>

          <p className="subtext">Upload your listing photos</p>
          <div className="edit-listing-photos-upload">
            <label className="edit-upload-button-label">
              <input
                type="file"
                multiple
                accept="image/jpeg, image/jpg, image/png"
                className="edit-image-upload-input"
                onChange={handleListingPhotoChange}
              />
              Choose Photos
            </label>
            <p className="edit-file-count">
              {listingPhotos.length} {listingPhotos.length === 1 ? 'photo' : 'photos'} selected
            </p>
            <p className="edit-upload-instructions">
              Upload multiple images. Images will be cropped to a vertical orientation.
            </p>
            <div className="edit-listing-photos-preview">
              {listingPhotos.map((photo) => (
                <div key={photo.preview} className="edit-photo-preview">
                  <img src={photo.preview} alt="Listing" />
                  <button
                    className="edit-delete-button"
                    onClick={() => handleDeleteListingPhoto(photo.preview)}
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="edit-form-section">
          <h3 className="edit-listing-subheader">Receiving Payments</h3>
          {payments ? (
            <button className="setup-stripe-button" disabled={disableEditPayments} onClick={editStripe}>Edit Payout Methods</button>
          ) : (
            <>
              <p className="need-stripe-warning">Stripe is required to host and receive payments.</p>
              <button className="setup-stripe-button" onClick={setupStripe}>Setup Stripe</button>
            </>
          )}
        </div>
        <div className="edit-form-section">
          <h3 className="edit-listing-subheader">Listing Details</h3>
          <div className="edit-form-fields">
            <CurrencyInput
              name="hourly-rate"
              className="edit-input-field hourly-rate"
              placeholder="Hourly Rate"
              value={rate}
              allowDecimals={false}
              prefix="$" // Dollar sign prefix
              suffix="/hour"
              onValueChange={handleRateChange} // Handle value change
            />
            <FormControl fullWidth variant="outlined" className="edit-select-form-control-ab">
              <TextField
                select
                id="edit-city-select-ab"
                value={selectedCity}
                label={cityPlaceHolder ? "City" : ""}
                onChange={handleCityChange}
                className="edit-city-select-ab"
                size="small"
                InputLabelProps={{
                  style: {
                    color: 'white',
                    marginTop: '15px',
                    left: "50%",
                    transform: "translateX(-50%)", // Center the label horizontally
                    textAlign: "center",
                  },
                  shrink: false, // Prevents label from floating
                }}
              >
                <MenuItem value="Miami, FL">Miami, FL</MenuItem>
                <MenuItem value="Tampa, FL">Tampa, FL</MenuItem>
              </TextField>
            </FormControl>
            <p className="edit-more-cities-text">More cities coming soon.</p>
          </div>
          <p className="edit-description-guidance">
            In 500 characters or less, describe your listing. This is your chance to
            showcase your personality and what you offer.
          </p>
          <div className="edit-description-container">
          <span className="edit-char-counter">{description.length}/500</span>

          <textarea className="edit-input-field description-input" value={description} onChange={(e) => setDescription(e.target.value)} placeholder={`Hi! I'm ${firstName || '[Your Name]'}, your ActivityBuddy. Let's make our time together fun and memorable! Whether it's hiking, exploring new spots, or trying out activities, I'm open to your ideas. Let's get creative and enjoy new experiences together!`}></textarea>
          </div>
        </div>
        {/* Supported Activities */}
        <div className="edit-form-section">
          <h3 className="edit-listing-subheader">Choose your Supported Activities</h3>
          <div className="edit-checkbox-container">
            <FormGroup className="edit-checkbox-group">
              <FormControlLabel
                control={<Checkbox name="outdoors" color="default" checked={checkBoxStates.outdoors} onChange={handleCheckboxChange} />}
                label="Outdoors: Hike, bike, and explore nature."
              />
              <FormControlLabel
                control={<Checkbox name="food" color="default" checked={checkBoxStates.food} onChange={handleCheckboxChange} />}
                label="Food & Drink: Discover new restaurants and bars."
              />
              <FormControlLabel
                control={<Checkbox name="fitness" color="default" checked={checkBoxStates.fitness} onChange={handleCheckboxChange} />}
                label="Fitness & Sports: Work out, do yoga, and play sports."
              />
              <FormControlLabel
                control={<Checkbox name="arts" color="default" checked={checkBoxStates.arts} onChange={handleCheckboxChange} />}
                label="Arts & Culture: Visit museums, theaters, and art events."
              />
              <FormControlLabel
                control={<Checkbox name="events" color="default" checked={checkBoxStates.events} onChange={handleCheckboxChange} />}
                label="Events: Attend local festivals and gatherings."
              />
              <FormControlLabel
                control={<Checkbox name="hobbies" color="default" checked={checkBoxStates.hobbies} onChange={handleCheckboxChange} />}
                label="Hobbies: Share interests like gardening or photography."
              />
              <FormControlLabel
                control={<Checkbox name="night" color="default" checked={checkBoxStates.night} onChange={handleCheckboxChange} />}
                label="Nightlife: Enjoy clubs, bars, and late-night events."
              />
              <FormControlLabel
                control={<Checkbox name="online" color="default" checked={checkBoxStates.online} onChange={handleCheckboxChange} />}
                label="Online: Connect through gaming and video chats."
              />
              <FormControlLabel
                control={<Checkbox name="safety" color="default" checked={checkBoxStates.safety} onChange={handleCheckboxChange} />}
                label="Safety: Provide company for safe walks and outings."
              />
            </FormGroup>
          </div>
          <div className="edit-form-divider"></div>
          {/* Error Messages */}
          {Object.keys(errors).length > 0 && (
            <div className="error-messages">
              {Object.values(errors).map((error, index) => (
                <p key={index} className="error">{error}</p>
              ))}
            </div>
          )}
          <button type="submit" disabled={isSubmitting || disableForm} onClick={handleSubmit} className="edit-submit-button">
            {isSubmitting ? <CircularProgress color="inherit" /> : 'Submit'}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditListing;
