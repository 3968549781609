import React from "react";
import "./PrivacyPolicy.css";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="privacy-policy-container">
        <h1 className="privacy-policy-title">Privacy Policy</h1>
        <div className="privacy-policy-content">
          <h2>1. Introduction</h2>
          <p>
            ActivityBuddy ("we," "us," or "our") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains our practices regarding the collection, use, disclosure, and protection of your personal information when you use our website, mobile applications, and services (collectively, the "Platform"). By accessing or using our Platform, you agree to the terms of this Privacy Policy.
          </p>

          <h2>2. Information We Collect</h2>
          <p><strong>2.1 Information You Provide:</strong> When you sign up for or use our services, we collect personal information that you voluntarily provide, including but not limited to:</p>
          <ul>
            <li>
              Name
            </li>
            <li>
              Email address
            </li>
            <li>
              City and state of residence
            </li>
            <li>
              Date of birth
            </li>
            <li>
              Photos
            </li>
            <li>
              Financial information (processed securely by our payment processor, Stripe)
            </li>
            <li>
              Activity preferences, skills, and listing description
            </li>
            <li>
              Any additional information you choose to include in your profile or communications with us
            </li>
          </ul>
          <p><strong>2.2 Information Collected Automatically:</strong> We automatically collect certain information about your device and how you interact with our Platform, including:</p>
          <ul>
            <li>
              IP address
            </li>
            <li>
              Device type and operating system
            </li>
            <li>
              Browser type and version
            </li>
            <li>
              Unique device identifiers
            </li>
            <li>
              Pages visited and actions taken on our Platform
            </li>
            <li>
              Date and time of access
            </li>
            <li>
              Referring website or application
            </li>
            <li>
              Language preferences
            </li>
            <li>
              Geolocation data (if enabled on your device)
            </li>
          </ul>

          <p><strong>2.3 Cookies and Similar Technologies:</strong> We use cookies, web beacons, and similar tracking technologies to collect information about your browsing behavior and preferences. These technologies help us analyze Platform usage, customize content, and deliver targeted advertisements. You can manage your cookie preferences through your browser settings, but please note that disabling certain cookies may impact your ability to use some features of our Platform.</p>
          <p><strong>2.4 Information from Third Parties:</strong> We may receive information about you from third-party sources, such as:</p>
          <ul>
            <li>
              Social media platforms (if you choose to connect your social media accounts)
            </li>
            <li>
              Public databases
            </li>
            <li>
              Marketing partners
            </li>
            <li>
              Analytics providers
            </li>
          </ul>

          <h2>3. How We Use Your Information</h2>
          <p>We use your personal information for the following purposes:</p>
          <ul>
            <li>Providing, maintaining, and improving our Platform and services.</li>
            <li>Processing secure payments and transactions through our payment processor.</li>
            <li>Communicating with you about your account, updates, and relevant offers.</li>
            <li>Analyzing user trends and engagement to enhance the Platform.</li>
            <li>Personalizing your experience and providing tailored content.</li>

            <li>Verifying your identity and preventing fraud.</li>

            <li>Responding to your inquiries and support requests.</li>

            <li>Complying with legal obligations and enforcing our Terms of Service.</li>

            <li>Sending you marketing communications (subject to your consent where required by law).</li>
            <li>Conducting research and analytics to improve our services.</li>

          </ul>

          <h2>4. Sharing Your Information</h2>
          <p>
            <strong>4.1 Service Providers: </strong> We may share your personal information with the following categories of recipients:
            <ul>
              <li>
                Payment processors (e.g., Stripe)
              </li>
              <li>
                Cloud hosting providers
              </li>
              <li>
                Analytics and data processing companies
              </li>
              <li>
                Customer support services
              </li>
              <li>
                Marketing and advertising partners
              </li>
            </ul>
            <p>These service providers are contractually obligated to use your information only as necessary to provide services to us and are prohibited from using it for their own purposes.</p>
            <p><strong>4.2 Other Users:</strong> Certain information from your profile (e.g., name, photo, activity preferences) may be visible to other users of the Platform to facilitate connections and bookings.</p>
            <p><strong>4.3 Legal Obligations:</strong> We may disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests, or to exercise or defend legal claims.</p>
            <p><strong>4.4 Business Transfers:</strong> If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</p>
            <p><strong>4.5 With Your Consent:</strong> We may share your information with third parties when you have given us your consent to do so. We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent.</p>
          </p>

          <h2>5. Data Security</h2>
          <p>
            We implement industry-standard technical, administrative, and physical security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
          </p>

          <h2>6. Your Rights and Choices</h2>
          <p>Depending on your jurisdiction, you may have the right to:</p>
          <ul>
            <li>The right to access and receive a copy of your personal information.</li>
            <li>The right to correct or update your personal informatio.n</li>
            <li>The right to request deletion of your personal information.</li>
            <li>The right to restrict or object to certain processing of your information.</li>

            <li>The right to data portability.</li>

            <li>The right to withdraw consent (where processing is based on consent).</li>

            <li>The right to opt-out of certain data uses (e.g., marketing communications).</li>

          </ul>
          <p>To exercise your rights, please contact us at support@activitybuddy.com.</p>

          <h2>7. Data Retention</h2>
          <p>
            We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. The criteria used to determine our retention periods include:
          </p>
          <ul>
            <li>The length of time we have an ongoing relationship with you.</li>
            <li>Whether there is a legal obligation to which we are subject.</li>
            <li>Whether retention is advisable in light of our legal position (e.g., in respect of applicable statutes of limitations, litigation, or regulatory investigations).</li>
          </ul>

          <h2>8. International Data Transfers</h2>
          <p>
            ActivityBuddy is based in the United States, and your information may be transferred to, stored, and processed in the United States or other countries where our service providers maintain facilities. These countries may have data protection laws that are different from those in your country of residence.

            When we transfer your information internationally, we take appropriate safeguards to protect your information in accordance with this Privacy Policy and applicable law. By using the Platform, you consent to the transfer of your information to countries outside of your country of residence, which may have different data protection rules than those of your country.
          </p>

          <h2>9. Children's Privacy</h2>
          <p>
          Our Platform is not directed to children under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us at support@activitybuddy.com, and we will take steps to delete such information.
          </p>
          <h2>10. Changes to This Privacy Policy</h2>
          <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated Privacy Policy on our Platform. Your continued use of the Platform after Privacy Policy revisions constitutes your acceptance of the updated terms.    
                </p>
          <h2>11. Contact Us</h2>
          <p>
          If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at support@activitybuddy.com.          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
