import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import "./Activities.css";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import AccountCircle from '@mui/icons-material/AccountCircle';


const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const Activities = () => {
    const navigate = useNavigate();
    const [activities, setActivities] = useState([]);
    const [isActivityBuddy, setIsActivityBuddy] = useState(false);
    const [tab, setTab] = useState("guest");

    useEffect(() => {
        const fetchActivities = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                navigate("/login");
                return;
            }
            const token = await user.getIdToken();
            try {
                const response = await fetch(`${API_BASE_URL}/activity/activities`, {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.ok) {
                    const data = await response.json();
                    setActivities(data.formattedActivities);
                    setIsActivityBuddy(data.isActivityBuddy);
                } else {
                    console.error("Failed to fetch activities.");
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchActivities();
    }, [navigate]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    // Categorize activities by their status

    const categorizedActivities = {
        guest: {
            pending: activities.filter(activity => activity.role === "customer" && activity.activity_status === "pending"),
            upcoming: activities.filter(activity => activity.role === "customer" && activity.activity_status === "approved"),
            history: activities.filter(activity => activity.role === "customer" && ["cancelled", "completed"].includes(activity.activity_status)),
        },
        host: {
            pending: activities.filter(activity => activity.role === "activitybuddy" && activity.activity_status === "pending"),
            upcoming: activities.filter(activity => activity.role === "activitybuddy" && activity.activity_status === "approved"),
            history: activities.filter(activity => activity.role === "activitybuddy" && ["cancelled", "completed"].includes(activity.activity_status)),
        },
    };

    const getNoActivitiesMessage = (tab) => {
        if (tab === "guest") {
            return "Book an activity first!";
        } else if (tab === "host") {
            return "Host an activity first!";
        }
    };

    const handleHostTabClick = () => {
        if (!isActivityBuddy) {
            navigate("/become-activitybuddy");
        } else {
            setTab("host");
        }
    }

    return (
        <>
            <Navbar />
            <div className="activities-container">
                <header className="my-activities-header">My Activities</header>
                <div className="activities-tab-container">
                    <button
                        className={`activities-tab ${tab === "guest" ? "active" : ""}`}
                        onClick={() => setTab("guest")}
                    >
                        Guest
                    </button>
                    <button
                        className={`activities-tab ${tab === "host" ? "active" : ""}`}
                        onClick={handleHostTabClick}
                    >
                        Host
                    </button>
                </div>

                <div className="activities-list">
                    {/* Display message when there are no activities for Guest tab */}
                    {tab === "guest" && categorizedActivities.guest.pending.length === 0 && categorizedActivities.guest.upcoming.length === 0 && categorizedActivities.guest.history.length === 0 && (
                        <p className="missing-activities-text">{getNoActivitiesMessage("guest")}</p>
                    )}

                    {/* Display message when there are no activities for Host tab */}
                    {tab === "host" && categorizedActivities.host.pending.length === 0 && categorizedActivities.host.upcoming.length === 0 && categorizedActivities.host.history.length === 0 && (
                        <p className="missing-activities-text">{getNoActivitiesMessage("host")}</p>
                    )}

                    {/* Display activities for the selected tab */}
                    {categorizedActivities[tab].pending.length > 0 && (
                        <>
                            <div className="activities-status-header">Pending</div>
                            {categorizedActivities[tab].pending.map(activity => (
                                <div
                                    key={activity.activity_id}
                                    className="activities-activity-card"
                                    onClick={() => navigate(`/activity/${activity.activity_id}`)}
                                >
                                    {activity.opposite_user.profile_photo ? (
                                        <img
                                            src={activity.opposite_user.profile_photo}
                                            alt={activity.opposite_user.first_name}
                                            className="activities-profile-pic"
                                        />
                                    ) : (
                                        <AccountCircle className="activities-profile-pic-placeholder" />
                                    )}
                                    <div className="activities-activity-info">
                                        <p className="activities-name">{activity.opposite_user.first_name}</p>
                                        <p className="activities-status">{capitalizeFirstLetter(activity.activity_status)}</p>
                                    </div>
                                    <div className="activities-time-info">
                                        <p className="activities-date">{new Date(activity.activity_date).toLocaleDateString()}</p>
                                        <p className="activities-time">{`${activity.activity_start_time} - ${activity.activity_end_time}`}</p>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}

                    {categorizedActivities[tab].upcoming.length > 0 && (
                        <>
                            <div className="activities-status-header">Upcoming</div>
                            {categorizedActivities[tab].upcoming.map(activity => (
                                <div
                                    key={activity.activity_id}
                                    className="activities-activity-card"
                                    onClick={() => navigate(`/activity/${activity.activity_id}`)}
                                >
                                    {activity.opposite_user.profile_photo ? (
                                        <img
                                            src={activity.opposite_user.profile_photo}
                                            alt={activity.opposite_user.first_name}
                                            className="activities-profile-pic"
                                        />
                                    ) : (
                                        <AccountCircle className="activities-profile-pic-placeholder" />
                                    )}
                                    <div className="activities-activity-info">
                                        <p className="activities-name">{activity.opposite_user.first_name}</p>
                                        <p className="activities-status">{capitalizeFirstLetter(activity.activity_status)}</p>
                                    </div>
                                    <div className="activities-time-info">
                                        <p className="activities-date">{new Date(activity.activity_date).toLocaleDateString()}</p>
                                        <p className="activities-time">{`${activity.activity_start_time} - ${activity.activity_end_time}`}</p>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}

                    {categorizedActivities[tab].history.length > 0 && (
                        <>
                            <div className="activities-status-header">History</div>
                            {categorizedActivities[tab].history.map(activity => (
                                <div
                                    key={activity.activity_id}
                                    className="activities-activity-card"
                                    onClick={() => navigate(`/activity/${activity.activity_id}`)}
                                >
                                    {activity.opposite_user.profile_photo ? (
                                        <img
                                            src={activity.opposite_user.profile_photo}
                                            alt={activity.opposite_user.first_name}
                                            className="activities-profile-pic"
                                        />
                                    ) : (
                                        <AccountCircle className="activities-profile-pic-placeholder" />
                                    )}
                                    <div className="activities-activity-info">
                                        <p className="activities-name">{activity.opposite_user.first_name}</p>
                                        <p className="activities-status">{capitalizeFirstLetter(activity.activity_status)}</p>
                                    </div>
                                    <div className="activities-time-info">
                                        <p className="activities-date">{new Date(activity.activity_date).toLocaleDateString()}</p>
                                        <p className="activities-time">{`${activity.activity_start_time} - ${activity.activity_end_time}`}</p>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Activities;
