import React, { useState } from 'react';
import styles from './Support.module.css';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';

const Support = () => {
  const [type, setType] = useState('Support');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_BASE_URL;


  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!message.trim()) {
      setError('Please enter a message.');
      return;
    }

    setError('');
    setIsSubmitting(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/support`, { // Update the URL if deployed
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, type, message }),
      });

      const data = await response.json();

      if (response.ok) {
        setShowConfirmation(true);
        setEmail('');
        setMessage('');
      } else {
        setError(data.error || 'Something went wrong.');
      }
    } catch (error) {
      setError('Unable to submit your request. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      < Navbar />
      <div className={styles['support-container']}>
        <h1 className={styles['support-title']}>Support & Feedback</h1>
        <p className={styles['support-helper-text']}>
          Need assistance or want to share your thoughts? Submit a support ticket or provide feedback below.
        </p>
        <form className={styles['support-form']} onSubmit={handleSubmit}>
          <div className={styles['support-type']}>
            <label>
              <input
                type="radio"
                value="Support"
                className={styles['radio']}
                checked={type === 'Support'}
                onChange={(e) => setType(e.target.value)}
                disabled={isSubmitting || showConfirmation}
              />
              Support
            </label>
            <label>
              <input
                type="radio"
                value="Feedback"
                className={styles['radio']}
                checked={type === 'Feedback'}
                onChange={(e) => setType(e.target.value)}
                disabled={isSubmitting || showConfirmation}
              />
              Feedback
            </label>
          </div>
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles['support-input']}
            autoComplete='off'
            disabled={isSubmitting || showConfirmation}
          />
          <textarea
            placeholder="Enter your message here"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={styles['support-textarea']}
            autoComplete='off'
            disabled={isSubmitting || showConfirmation}
          />
          {error && <p className={styles['support-error']}>{error}</p>}
          <button type="submit" className={styles['support-submit']} disabled={isSubmitting || showConfirmation}>
            Submit
          </button>
        </form>
        {showConfirmation && (
          <p className={styles['support-thankyou']}>
            Thank you for contacting us. If this is a support ticket, we'll reach out to you soon.
          </p>
        )}
      </div>
      < Footer />
    </>
  );
};

export default Support;
