import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormControl, InputLabel, Select, MenuItem, TextField, CircularProgress } from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { AccountCircle, ArrowBackIos } from '@mui/icons-material';
import { getAuth } from "firebase/auth";
import "./Activity.css";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";

const API_BASE_URL = process.env.REACT_APP_BASE_URL;


const Activity = () => {
    const { id } = useParams();
    const [activityDetails, setActivityDetails] = useState(null);
    const [showReportInfo, setShowReportInfo] = useState(false);
    const [buttonError, setButtonError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCancelModalOpen, setCancelModalOpen] = useState(false);
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [cancelReason, setCancelReason] = useState(null);
    const [cancelError, setCancelError] = useState(false);
    const [reviewError, setReviewError] = useState(false);
    const [rating, setRating] = useState(0);
    const [ratingComment, setRatingComment] = useState("");
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const navigate = useNavigate();
    const SERVICE_FEE = 0.15;
    const TAX_RATE = 0.07

    useEffect(() => {
        const fetchActivityDetails = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                navigate('/login')
                return;
            }
            const token = await user.getIdToken();
            try {
                const response = await fetch(`${API_BASE_URL}/activity/${id}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                if (response.ok) {
                    const activityDetails = await response.json();
                    setActivityDetails(activityDetails); // Store the fetched data in state
                } else {
                    // Handle errors
                    console.error("Failed to fetch activity details.");
                }
            } catch (error) {
                console.log(error)
            }
        };
        fetchActivityDetails();
    }, [id, refreshTrigger, navigate]);

    const formatCurrency = (cents) => (cents / 100).toFixed(2);
    const earnings = (totalCents) => {
        const totalDollars = totalCents / 100;
        const baseRate = totalDollars / (1 + SERVICE_FEE + (SERVICE_FEE * TAX_RATE));
        return Math.round(baseRate * 100);
    };
    const formatDate = (dateString) => new Date(dateString).toLocaleDateString("en-US");
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    const handleReportClick = () => {
        setShowReportInfo(!showReportInfo);
    };

    const showCancelButton = () => {
        // Parse the activity_start_time string into a 24-hour format
        const parseTime = (timeString) => {
            const [time, period] = timeString.split(/([APM]+)/); // Splitting the time and period (AM/PM)
            let [hours, minutes] = time.split(':').map(num => parseInt(num, 10));

            // Convert to 24-hour format
            if (period.includes('PM') && hours < 12) hours += 12;
            if (period.includes('AM') && hours === 12) hours = 0;

            return { hours, minutes };
        };

        // Convert activity date to a UTC date object
        const activityStartDateTime = new Date(activityDetails?.activity_date); // Assuming activity_date is already a Date object
        const { hours, minutes } = parseTime(activityDetails?.activity_start_time);
        activityStartDateTime.setHours(hours, minutes, 0, 0);

        // Convert activity start time to Florida time (EST/EDT)
        const floridaTimeZone = "America/New_York";
        const activityStartET = new Date(activityStartDateTime.toLocaleString("en-US", { timeZone: floridaTimeZone }));

        // Compute cancellation deadline (24 hours before start)
        const cancelDeadlineET = new Date(activityStartET.getTime() - (24 * 60 * 60 * 1000));

        // Get current time in Florida time (ET)
        const currentTimeET = new Date(new Date().toLocaleString("en-US", { timeZone: floridaTimeZone }));

        return currentTimeET <= cancelDeadlineET;
    };

    const handleApprove = async () => {
        setButtonError(false);
        if (!activityDetails) return;
        setIsSubmitting(true)
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
            navigate('/login')
            setIsSubmitting(false)
            return;
        }
        const token = await user.getIdToken();
        if (!token) {
            navigate('/login')
            setIsSubmitting(false)
            return;
        }
        try {
            const activityResponse = await fetch(`${API_BASE_URL}/activity/approve`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ activityId: activityDetails.activity_id }),
            });

            setRefreshTrigger(prev => !prev);
        } catch (error) {
            setButtonError(true);
            return;
        } finally {
            setIsSubmitting(false);
        }
    }

    const handleDecline = async () => {
        setButtonError(false);
        if (!activityDetails) return;

        setIsSubmitting(true)
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
            navigate('/login')
            setIsSubmitting(false)
            return;
        }
        const token = await user.getIdToken();
        if (!token) {
            navigate('/login')
            setIsSubmitting(false)
            return;
        }
        try {
            const activityResponse = await fetch(`${API_BASE_URL}/activity/decline`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ activityId: activityDetails.activity_id }),
            });

            if (!activityResponse.ok) {
                setButtonError(true);
                setIsSubmitting(false);
                return
            }
            setRefreshTrigger(prev => !prev);
        } catch (error) {
            setButtonError(true);
            return;
        } finally {
            setIsSubmitting(false);
        }
    }

    const handleCancel = async () => {
        setButtonError(false);
        setCancelError(false);
        if (cancelReason === null) {
            setCancelError(true);
            return;
        }
        if (!activityDetails) return;
        setIsSubmitting(true)
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
            navigate('/login')
            setIsSubmitting(false)
            return;
        }
        const token = await user.getIdToken();
        if (!token) {
            navigate('/login')
            setIsSubmitting(false)
            return;
        }
        try {
            const activityResponse = await fetch(`${API_BASE_URL}/activity/cancel`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ activityId: activityDetails.activity_id, cancelReason: cancelReason }),
            });

            if (!activityResponse.ok) {
                setCancelModalOpen(false);
                setButtonError(true);
                setIsSubmitting(false);
                return
            }
            setRefreshTrigger(prev => !prev);
        } catch (error) {
            setButtonError(true);
            setCancelModalOpen(false);
            return;
        } finally {
            setIsSubmitting(false);
            setCancelModalOpen(false);
        }
    }

    const handleReview = async () => {
        setButtonError(false);
        setReviewError(false);
        if (!ratingComment || rating === 0) {
            setReviewError(true);
            return;
        }
        if (!activityDetails) return;
        setIsSubmitting(true)
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
            navigate('/login')
            setIsSubmitting(false)
            return;
        }
        const token = await user.getIdToken();
        if (!token) {
            navigate('/login')
            setIsSubmitting(false)
            return;
        }
        try {
            const activityResponse = await fetch(`${API_BASE_URL}/activity/review`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ activityId: activityDetails.activity_id, ratingComment: ratingComment, rating: rating }),
            });

            if (!activityResponse.ok) {
                setReviewModalOpen(false);
                setButtonError(true);
                setIsSubmitting(false);
                return
            }
            setRefreshTrigger(prev => !prev);
        } catch (error) {
            setReviewModalOpen(false);
            setButtonError(true);
            return;
        } finally {
            setIsSubmitting(false);
            setReviewModalOpen(false);
        }
    }

    if (!activityDetails) {
        return <CircularProgress />;
    }
    return (
        <>
            <Navbar />
            <div className="activity-container">
                <ArrowBackIos onClick={() => navigate(`/activities`)} fontSize="large" className="back-to-activities-button" />
                <h1 className="activity-heading">
                    Your Activity with {activityDetails?.isActivityBuddy ? activityDetails?.customer.first_name : activityDetails?.activitybuddy.first_name}
                </h1>
                {activityDetails?.isCustomer ? (
                    // If the user is a customer, show activity buddy's photo (fallback to AccountCircle if no photo)
                    activityDetails.activitybuddy?.profile_photo ? (
                        <img
                            src={activityDetails.activitybuddy.profile_photo}
                            alt="ActivityBuddy Profile"
                            className="activity-profile-photo"
                        />
                    ) : (
                        <AccountCircle className="activity-profile-photo-placeholder" fontSize="large" />
                    )
                ) : (
                    // If the user is an activity buddy, show customer's photo (fallback to AccountCircle if no photo)
                    activityDetails.customer?.profile_photo ? (
                        <img
                            src={activityDetails.customer.profile_photo}
                            alt="Customer Profile"
                            className="activity-profile-photo"
                        />
                    ) : (
                        <AccountCircle className="activity-profile-photo-placeholder" fontSize="large" />
                    )
                )}

                <h2 className="activity-subheading">Activity Details</h2>
                <div className="activity-details">
                    <p className="activity-text-details"><strong>Status</strong> <span>{capitalize(activityDetails?.activity_status)}</span></p>
                    <p className="activity-text-details"><strong>Date</strong> <span>{formatDate(activityDetails?.activity_date)}</span></p>
                    <p className="activity-text-details"><strong>Start Time</strong> <span>{activityDetails?.activity_start_time}</span></p>
                    <p className="activity-text-details"><strong>End Time</strong> <span>{activityDetails?.activity_end_time}</span></p>
                </div>
                <div className="activity-description-container">
                    <h2 className="activity-description-header">Description</h2>
                    <p className="activity-description-text">{activityDetails?.description}</p>
                </div>
                {/* Details for ActivityBuddy */}
                {activityDetails?.isActivityBuddy && (
                    <>
                        <h2 className="activity-subheading">Earning Details</h2>
                        {activityDetails?.activity_status === "approved" ? (
                            <>
                                <p className="activity-text-price">You will earn ${formatCurrency(earnings(activityDetails?.cost))} after completing this activity.</p>
                                <h2 className="activity-subheading">Actions</h2>
                                <button onClick={() => navigate(`/message/${activityDetails?.chat_thread_id?._id}`)} className="activity-button activity-button-primary">
                                    <MailOutlineIcon />
                                    <p>Message</p>
                                </button>
                                <div className="button-group-links">
                                    {showCancelButton() && (
                                        <button onClick={() => setCancelModalOpen(true)} className="activity-link-button">Cancel</button>
                                    )}
                                    <button className="activity-link-button" onClick={handleReportClick}>Report</button>
                                </div>
                                {showReportInfo && (
                                    <div className="report-info">
                                        <p>
                                            To report an issue, please use our <a href="/contact-us" target="_blank" rel="noopener noreferrer">Support Form</a>.
                                        </p>
                                    </div>
                                )}
                                <p className="activity-text-cancel">You can cancel up to 24 hours before your scheduled activity.</p>
                            </>
                        ) : activityDetails?.activity_status === "completed" ? (
                            <>
                                <p className="activity-text-price">
                                    You earned ${formatCurrency(earnings(activityDetails?.cost))} for completing this activity.
                                    Login to your <a href="https://dashboard.stripe.com" target="_blank" rel="noopener noreferrer">Stripe account</a> to see the date of your expected payout.
                                </p>                                
                                <h2 className="activity-subheading">Actions</h2>
                                <div className="button-group-links">
                                    {/* Check if both reviews are empty */}
                                    {(!activityDetails?.activitybuddy_review || Object.keys(activityDetails?.activitybuddy_review).length === 0) && (
                                        <button onClick={() => setReviewModalOpen(true)} className="activity-link-button">
                                            Leave Review
                                        </button>
                                    )}
                                    <button className="activity-link-button" onClick={handleReportClick}>Report</button>
                                </div>
                                {showReportInfo && (
                                    <div className="report-info">
                                        <p>
                                            To report an issue, please use our <a href="/contact-us" target="_blank" rel="noopener noreferrer">Support Form</a>.
                                        </p>
                                    </div>
                                )}
                            </>
                        ) : activityDetails?.activity_status === "cancelled" ? (
                            <>
                                <p className="activity-text">This activity was cancelled so you did not earn ${formatCurrency(earnings(activityDetails?.cost))}.</p>
                                <h2 className="activity-subheading">Actions</h2>
                                <div className="button-group-links">
                                    <button className="activity-link-button" onClick={handleReportClick}>Report</button>
                                </div>
                                {showReportInfo && (
                                    <div className="report-info">
                                        <p>
                                            To report an issue, please use our <a href="/contact-us" target="_blank" rel="noopener noreferrer">Support Form</a>.
                                        </p>
                                    </div>
                                )}
                            </>
                        ) : activityDetails?.activity_status === "pending" ? (
                            <>
                                <p className="activity-text-price">You will earn ${formatCurrency((earnings(activityDetails?.cost)))} after completing this activity.</p>
                                <h2 className="activity-subheading">Actions</h2>

                                <div className="button-group">
                                    <button onClick={handleApprove} className="activity-button-host activity-button-primary">Accept</button>
                                    <button onClick={handleDecline} className="activity-button-host activity-button-secondary">Decline</button>
                                </div>
                                <button onClick={() => navigate(`/message/${activityDetails?.chat_thread_id?._id}`)} className="activity-button activity-button-primary">
                                    <MailOutlineIcon />
                                    <p>Message</p>
                                </button>
                                {buttonError && <p className="activity-error-message">Action failed. Please contact support.</p>}
                                <button className="activity-link-button" onClick={handleReportClick}>Report</button>
                                {showReportInfo && (
                                    <div className="report-info">
                                        <p>
                                            To report an issue, please use our <a href="/contact-us" target="_blank" rel="noopener noreferrer">Support Form</a>.
                                        </p>
                                    </div>
                                )}
                                <p className="activity-text-cancel">You can cancel up to 24 hours before your scheduled activity.</p>
                            </>
                        ) : null}
                    </>
                )}

                {/*  Details for Customer */}
                {activityDetails?.isCustomer && (
                    <>
                        <h2 className="activity-subheading">Price Details</h2>
                        {activityDetails?.activity_status === "approved" ? (
                            <>
                                <p className="activity-text-price">Total price: ${formatCurrency(activityDetails?.cost)}</p>
                                <h2 className="activity-subheading">Actions</h2>
                                <button onClick={() => navigate(`/message/${activityDetails?.chat_thread_id?._id}`)} className="activity-button activity-button-primary">
                                    <MailOutlineIcon />
                                    <p>Message</p>
                                </button>
                                <div className="button-group-links">
                                    {showCancelButton() && (
                                        <button onClick={() => setCancelModalOpen(true)} className="activity-link-button">Cancel</button>
                                    )}
                                    <button className="activity-link-button" onClick={handleReportClick}>Report</button>
                                </div>
                                {showReportInfo && (
                                    <div className="report-info">
                                        <p>
                                            To report an issue, please use our <a href="/contact-us" target="_blank" rel="noopener noreferrer">Support Form</a>.
                                        </p>
                                    </div>
                                )}
                                <p className="activity-text-cancel">You can cancel up to 24 hours before your scheduled activity.</p>
                            </>
                        ) : activityDetails?.activity_status === "completed" ? (
                            <>
                                <p className="activity-text-price">Total price: ${formatCurrency(activityDetails?.cost)}</p>
                                <h2 className="activity-subheading">Actions</h2>
                                <div className="button-group-links">
                                    {(!activityDetails?.customer_review || Object.keys(activityDetails?.customer_review).length === 0) && (
                                        <button onClick={() => setReviewModalOpen(true)} className="activity-link-button">
                                            Leave Review
                                        </button>
                                    )}
                                    <button className="activity-link-button" onClick={handleReportClick}>Report</button>
                                </div>
                                {showReportInfo && (
                                    <div className="report-info">
                                        <p>
                                            To report an issue, please use our <a href="/contact-us" target="_blank" rel="noopener noreferrer">Support Form</a>.
                                        </p>
                                    </div>
                                )}
                            </>
                        ) : activityDetails?.activity_status === "cancelled" ? (
                            <>
                                <p className="activity-text">Total refund: ${formatCurrency(activityDetails?.cost)}</p>
                                <h2 className="activity-subheading">Actions</h2>
                                <div className="button-group-links">
                                    <button className="activity-link-button" onClick={handleReportClick}>Report</button>
                                </div>
                                {showReportInfo && (
                                    <div className="report-info">
                                        <p>
                                            To report an issue, please use our <a href="/contact-us" target="_blank" rel="noopener noreferrer">Support Form</a>.
                                        </p>
                                    </div>
                                )}
                            </>
                        ) : activityDetails?.activity_status === "pending" ? (
                            <>
                                <p className="activity-text-price">Total price: ${formatCurrency(activityDetails?.cost)}</p>
                                <h2 className="activity-subheading">Actions</h2>
                                <button onClick={() => navigate(`/message/${activityDetails?.chat_thread_id?._id}`)} className="activity-button activity-button-primary">
                                    <MailOutlineIcon />
                                    <p>Message</p>
                                </button>
                                <div className="button-group-links">
                                    {showCancelButton() && (
                                        <button onClick={() => setCancelModalOpen(true)} className="activity-link-button">Cancel</button>
                                    )}
                                    <button className="activity-link-button" onClick={handleReportClick}>Report</button>
                                </div>
                                {showReportInfo && (
                                    <div className="report-info">
                                        <p>
                                            To report an issue, please use our <a href="/contact-us" target="_blank" rel="noopener noreferrer">Support Form</a>.
                                        </p>
                                    </div>
                                )}
                                <p className="activity-text-cancel">You can cancel up to 24 hours before your scheduled activity.</p>

                            </>
                        ) : null}
                    </>
                )}
            </div>
            <Footer />
            {/* Cancellation Modal */}
            {isCancelModalOpen && (
                <div className="activity-modal-overlay">
                    <div className="activity-modal">
                        <button className="activity-close-button" onClick={() => setCancelModalOpen(false)}>✕</button>
                        <h2>Cancel Activity</h2>
                        <textarea
                            placeholder="Enter your reason for cancellation"
                            value={cancelReason}
                            className="cancellation-reason-textarea"
                            onChange={(e) => setCancelReason(e.target.value)}
                        />
                        {cancelError && <p className="activity-error-message">Please enter a cancellation reason.</p>}
                        <button className="activity-cancel-submit-button" onClick={handleCancel}>Submit</button>
                    </div>
                </div>
            )}
            {/* Review Modal */}
            {isReviewModalOpen && (
                <div className="activity-modal-overlay">
                    <div className="activity-modal">
                        <button className="activity-close-button" onClick={() => setReviewModalOpen(false)}>✕</button>
                        <h2>Leave a Review</h2>
                        <div className="star-container">
                            {[1, 2, 3, 4, 5].map((num) => (
                                <span
                                    key={num}
                                    className={`star ${num <= rating ? "selected" : ""}`}
                                    onClick={() => setRating(num)}
                                >
                                    ★
                                </span>
                            ))}
                        </div>
                        <textarea
                            placeholder="Write your review"
                            value={ratingComment}
                            className="cancellation-reason-textarea"
                            onChange={(e) => setRatingComment(e.target.value)}
                        />
                        {reviewError && <p className="activity-error-message">Please select number of stars and write a review.</p>}
                        <button className="activity-cancel-submit-button" onClick={handleReview}>Submit</button>
                    </div>
                </div>
            )}
        </>
    );

}
export default Activity;
